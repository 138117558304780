import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import { PaletteColor, PaletteColorOptions, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import store from './store/store';
import { IntroMobile } from './components/Intro';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

declare module '@mui/material/styles' {
  interface Palette {
    swagalTeal: PaletteColor;
    swagalBlack: PaletteColor;
    swagalWhite: PaletteColor;
    textSecondary: PaletteColor;
    bgDefault: PaletteColor;
    bgLight: PaletteColor;
    bgLight30: PaletteColor;
    bgTeal: PaletteColor;
    bgDisabled: PaletteColor;
    textDisabled: PaletteColor;
    yellow: PaletteColor;
    red: PaletteColor;
    teal10: PaletteColor;
    teal20: PaletteColor;
    teal40: PaletteColor;
    invis: PaletteColor;
  }

  interface PaletteOptions {
    swagalTeal: PaletteColorOptions;
    swagalBlack: PaletteColorOptions;
    swagalWhite: PaletteColorOptions;
    textSecondary: PaletteColorOptions;
    bgDefault: PaletteColorOptions;
    bgLight: PaletteColorOptions;
    bgLight30: PaletteColorOptions;
    bgTeal: PaletteColorOptions;
    bgDisabled: PaletteColorOptions;
    textDisabled: PaletteColorOptions;
    yellow: PaletteColorOptions;
    red: PaletteColorOptions;
    teal10: PaletteColorOptions;
    teal20: PaletteColorOptions;
    teal40: PaletteColorOptions;
    invis: PaletteColorOptions;
  }
}

const outerTheme = createTheme({
  palette: {
    swagalTeal: { main: "#54A492" },
    swagalBlack: { main: "#231F20" },
    swagalWhite: { main: "#FFFFFF" },
    textSecondary: { main: "#8F8F8F" },
    bgDefault: { main: "#EFEFEF" },
    bgLight: { main: "#FAFAFA" },
    bgLight30: { main: "rgba(250, 250, 250, 0.3)" },
    bgTeal: { main: 'rgba(217, 233, 229, 1)' },
    bgDisabled: { main: "#CDCDCD" },
    textDisabled: { main: "#CDCDCD" },
    yellow: { main: "#FFD166" },
    red: { main: "#C74B46" },
    teal10: { main: "rgba(84, 164, 146, 0.1)" },
    teal20: { main: "rgba(84, 164, 146, 0.2)" },
    teal40: { main: "rgba(84, 164, 146, 0.4)" },
    invis: { main: "rgba(0, 0, 0, 0.0)" },
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
});

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    swagalTeal: true,
    swagalBlack: true,
    swagalWhite: true,
    textSecondary: true,
    bgDefault: true,
    bgLight: true,
    bgDisabled: true,
    textDisabled: true,
    yellow: true,
    red: true,
    teal10: true,
    teal20: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    swagalTeal: true,
    swagalBlack: true,
    swagalWhite: true,
    textSecondary: true,
    bgDefault: true,
    bgLight: true,
    bgDisabled: true,
    textDisabled: true,
    yellow: true,
    red: true,
    teal10: true,
    teal20: true
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    swagalTeal: true,
    swagalBlack: true,
    swagalWhite: true,
    textSecondary: true,
    bgDefault: true,
    bgLight: true,
    bgDisabled: true,
    textDisabled: true,
    yellow: true,
    red: true,
    teal10: true,
    teal20: true
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    swagalTeal: true,
    swagalBlack: true,
    swagalWhite: true,
    textSecondary: true,
    bgDefault: true,
    bgLight: true,
    bgDisabled: true,
    textDisabled: true,
    yellow: true,
    red: true,
    teal10: true,
    teal20: true
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    swagalTeal: true,
    swagalBlack: true,
    swagalWhite: true,
    textSecondary: true,
    bgDefault: true,
    bgLight: true,
    bgDisabled: true,
    textDisabled: true,
    yellow: true,
    red: true,
    teal10: true,
    teal20: true
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    swagalTeal: true,
    swagalBlack: true,
    swagalWhite: true,
    textSecondary: true,
    bgDefault: true,
    bgLight: true,
    bgDisabled: true,
    textDisabled: true,
    yellow: true,
    red: true,
    teal10: true,
    teal20: true
  }
}

console.log(`%c                                                                                                   
             WWWWWWWW                          WWWWWWWW                                                   lllllll 
             W::::::W                          W::::::W                                                   l:::::l 
             W::::::W                          W::::::W                                                   l:::::l 
             W::::::W                          W::::::W                                                   l:::::l 
    ssssssssssW:::::W           WWWWW          W:::::Waaaaaaaaaaaaa     ggggggggg   ggggg aaaaaaaaaaaaa    l::::l 
  ss::::::::::sW:::::W         W:::::W         W:::::Wa::::::::::::a   g:::::::::ggg::::g a::::::::::::a   l::::l 
ss:::::::::::::sW:::::W       W:::::::W       W:::::W aaaaaaaaa:::::a g:::::::::::::::::g aaaaaaaaa:::::a  l::::l 
s::::::ssss:::::sW:::::W     W:::::::::W     W:::::W           a::::ag::::::ggggg::::::gg          a::::a  l::::l 
  s:::::s  ssssss W:::::W   W:::::W:::::W   W:::::W     aaaaaaa:::::ag:::::g     g:::::g    aaaaaaa:::::a  l::::l 
    s::::::s       W:::::W W:::::W W:::::W W:::::W    aa::::::::::::ag:::::g     g:::::g  aa::::::::::::a  l::::l 
      s::::::s      W:::::W:::::W   W:::::W:::::W    a::::aaaa::::::ag:::::g     g:::::g a::::aaaa::::::a  l::::l 
ssssss   s:::::s     W:::::::::W     W:::::::::W    a::::a    a:::::ag::::::g    g:::::ga::::a    a:::::a  l::::l 
s:::::ssss::::::s     W:::::::W       W:::::::W     a::::a    a:::::ag:::::::ggggg:::::ga::::a    a:::::a l::::::l
s::::::::::::::s       W:::::W         W:::::W       a:::::aaaa::::::a g::::::::::::::::ga:::::aaaa::::::al::::::l
s:::::::::::ss          W:::W           W:::W         a::::::::::aa:::a gg::::::::::::::g a::::::::::aa:::al::::::l
sssssssssss              WWW             WWW           aaaaaaaaaa  aaaa   gggggggg::::::g  aaaaaaaaaa  aaaallllllll
                                                                                  g:::::g                          
                                                                      gggggg      g:::::g                          
                                                                      g:::::gg   gg:::::g                          
                                                                       g::::::ggg:::::::g                          
                                                                        gg:::::::::::::g                           
                                                                          ggg::::::ggg                             
                                                                             gggggg    
`, 'background: #ffffff; color: #54A492')
console.log(`%cPowered by Michi, Julian, Tobias, Mario - 2023`, 'background: #ffffff; color: #54A492')
console.log(`%cWe are ready and excited about feedback, new projects and ideas 🚀!`, 'background: #ffffff; color: #54A492')

root.render(
  <React.StrictMode>
    <ThemeProvider theme={outerTheme}>
      <Provider store={store}>
    <Router>
      <App></App>
           
      </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
