import { createSlice } from '@reduxjs/toolkit';

const introSlice = createSlice({
  name: 'intro',
  initialState: {
    open: localStorage.getItem('introOpen')
  },
  reducers: { 
    closeIntro(state) {
      state.open = "false"
      localStorage.setItem('introOpen', "false")
    }
  },
});

export const { closeIntro } = introSlice.actions;

export default introSlice.reducer;