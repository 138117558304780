import { Box, Button, Hidden, Typography } from "@mui/material";
import { ProductDto, ProductDtoUnitEnum } from "../../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SwipeableEdgeDrawer } from "../ProductDrawer";
import { getUnitText } from "../../util/functions";

interface ProductProps {
  product: ProductDto
}

export const ProductCard: React.FC<ProductProps> = ({
  product
}: ProductProps) => {
  const { productId } = useParams();
  const borderColor = (product.shop + "-" + product.id) == productId ? "swagalTeal.main" : "swagalWhite.main"
  const boxShadow = (product.shop + "-" + product.id) == productId ? "0px -1px 10px 0px #0000001A" : "none"
  return (
    <>
      <Hidden mdDown>
        <Box height={360} width={180} display="inline-block" position="relative" sx={{
          borderColor: borderColor, borderStyle: "solid", borderWidth: "2px", borderRadius: "8px", padding: "4px",
          boxShadow: boxShadow
        }}>
          <ProductCardImage product={product}></ProductCardImage>
          <ProductCardFooter product={product}></ProductCardFooter>
          <ProductCardBadges product={product}></ProductCardBadges>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box height={360} width={150} display="inline-block" position="relative" sx={{
          borderColor: borderColor, borderStyle: "solid", borderWidth: "2px", borderRadius: "8px",
          boxShadow: boxShadow
        }}>
          <ProductCardImage product={product}></ProductCardImage>
          <ProductCardFooter product={product}></ProductCardFooter>
          <ProductCardBadges product={product}></ProductCardBadges>
        </Box>
      </Hidden>
    </>
  );
}

const prettyLiters = (value: number): string => {
  if (value >= 1.0) {
    return `${value.toWeight()} l`
  }

  return `${Math.round(value * 1000)} ml`
};

const prettyKilograms = (value: number): string => {
  if (value >= 1.0) {
    return `${value.toWeight()} kg`
  }

  return `${Math.round(value * 1000)} g`
};

export const getPrettyAmount = (product: ProductDto): string => {
  switch (product.unit) {
    case ProductDtoUnitEnum.None: return ''
    case ProductDtoUnitEnum.Stk: return `${Math.round(product.amount)} Stk`
    case ProductDtoUnitEnum.L: return prettyLiters(product.amount)
    case ProductDtoUnitEnum.Kg: return prettyKilograms(product.amount)
    case ProductDtoUnitEnum.Meter: return `${product.amount.toWeight()} m`
    default: return ''
  }
};

const ProductCardBadges: React.FC<ProductProps> = ({
  product
}: ProductProps) => {
  return (
    <>
      {product.unit !== ProductDtoUnitEnum.None &&
        <Box sx={{
          paddingTop: "2px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px", borderRadius: "100px", margin: "-15px",
          position: "absolute", right: "32px", top: "24px", backgroundColor: "bgDefault.main"
        }}>
          <Typography fontSize={12}>{getPrettyAmount(product)}</Typography>
        </Box>
      }
      {product.salesBadge != null ?
        (
          <Box sx={{
            paddingTop: "2px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px", borderRadius: "100px", margin: "-15px",
            position: "absolute", right: "32px", top: "50px", backgroundColor: "swagalTeal.main"
          }}>
            <Typography fontSize={12} color="swagalWhite.main" fontWeight={700}>{product.salesBadge}</Typography>
          </Box>) : (<></>)}
    </>
  )
}

const ProductCardImage: React.FC<ProductProps> = ({
  product
}: ProductProps) => {
  return (
    <>
      <Hidden mdDown>
        <Box display="flex"
          flexDirection="column"
          sx={{ width: "180px", height: "240px" }}
          justifyContent="center"
          alignItems="center">
          <Box
            display="flex"
            position="relative"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "180px", height: "180px" }}
          >
            <img
              src={product.image}
              alt="swagal"
              loading="lazy"
              style={{ position: "absolute", top: "15px", maxWidth: "130px", maxHeight: "130px" }}
            />
          </Box>

          <ProductCardTitle product={product}></ProductCardTitle>
        </Box>
      </Hidden >
      <Hidden mdUp>
        <Box display="flex"
          flexDirection="column"
          sx={{ width: "150px", height: "240px" }}
          justifyContent="center"
          alignItems="center">
          <Box
            display="flex"
            position="relative"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "180px", height: "180px" }}
          >
            <img
              src={product.image}
              alt="swagal"
              loading="lazy"
              style={{ position: "absolute", top: "15px", maxWidth: "130px", maxHeight: "130px" }}
            />
          </Box>

          <ProductCardTitle product={product}></ProductCardTitle>
        </Box>
      </Hidden>
    </>

  )
}

const ProductCardTitle: React.FC<ProductProps> = ({
  product
}: ProductProps) => {
  return (
    <Box>
      <Typography align="center" fontSize={12} color="textSecondary.main">{product.shop}</Typography>
      <Typography align="center" fontSize={14} textOverflow="break-word" maxWidth={180} color="swagalBlack.main">{product.name.length > 32 ? product.name.slice(0, 38) + '...' : product.name}</Typography>
    </Box>
  )
}

const ProductCardFooter: React.FC<ProductProps> = ({
  product
}: ProductProps) => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const numberString = product.currentPrice.toSwagalAmount({ includeCurrency: true, spaceBetween: false });
  const [frontPart, backPart] = numberString.split(',');

  const handleOpenProductDetails = () => {
    navigate("/products/" + product.shop + "-" + product.id)
  }
  return (
    <>
      <Hidden mdDown>
        <Box width="140px" marginTop="8px" marginLeft="auto" marginRight="auto" display="flex" flexDirection="column" position="relative" bottom="0px" justifyContent="center"
          alignItems="center">
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <Typography align="center" fontSize={32} fontWeight={600} color="swagalBlack.main">{frontPart}</Typography>
            <Typography sx={{ paddingBottom: "16px", paddingLeft: "4px" }} fontWeight={600} fontSize={16} color="swagalBlack.main">{backPart}</Typography>
          </Box>
          <Typography align="center" fontSize={12} color="textSecondary.main">
            {product.unit !== ProductDtoUnitEnum.None
              ? `€ ${product.normalizedPrice?.toSwagalAmount({ includeCurrency: false }) + " / " + getUnitText(product.unit)}`
              : '-'}
          </Typography>
          <Button onClick={handleOpenProductDetails}
            disableElevation
            variant="contained"
            color="bgDefault"
            sx={{ width: "140px", marginTop: "12px", padding: "8px, 16px, 8px, 16px" }}>
            <Typography color="swagalBlack.main" fontWeight="600" fontSize={10}>DETAILS ANZEIGEN</Typography></Button>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Box width="130px" marginTop="8px" marginLeft="auto" marginRight="auto" display="flex" flexDirection="column" position="relative" bottom="0px" justifyContent="center"
          alignItems="center">
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <Typography align="center" fontSize={32} fontWeight={600} color="swagalBlack.main">{frontPart}</Typography>
            <Typography sx={{ paddingBottom: "16px", paddingLeft: "4px" }} fontWeight={600} fontSize={16} color="swagalBlack.main">{backPart}</Typography>
          </Box>
          <Typography align="center" fontSize={12} color="textSecondary.main">
            {product.unit !== ProductDtoUnitEnum.None
              ? `€ ${product.normalizedPrice?.toSwagalAmount({ includeCurrency: false }) + " / " + getUnitText(product.unit)}`
              : '-'}
          </Typography>
          <Box sx={{ marginTop: "18px" }}>
            <SwipeableEdgeDrawer productId={product.shop + "-" + product.id}></SwipeableEdgeDrawer>
          </Box>
        </Box>
      </Hidden>
    </>

  )
}
