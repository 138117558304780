import { CartCategoryDto, CartDtoShopsSelectedEnum, CartItemComparisonDto, ProductDto, ProductDtoCurrentPriceClassificationEnum, ProductDtoSwagalCategoryEnum, ProductDtoUnitEnum } from "../../api/api";


export interface UIProductPrice {
    price: number,
    shopId: string,
    leastExpensive: boolean
}

export interface CartProductStats {
    productShopPrices: UIProductPrice[],
    absDiff: number,
    relDiff: string,
    referenceAmount: number | null,
    unit: ProductDtoUnitEnum
}

const getPrice = (unitsMatch: boolean, referenceAmount: number | null) => (
    (product: ProductDto): number => {
        if (unitsMatch && referenceAmount) {
            return product.normalizedPrice! * referenceAmount
        } else {
            return product.currentPrice
        }
    }
);

const getReferenceAmount = (products: ProductDto[]) => {
    const amountSum = products
        .map(product => product.amount)
        .reduce((a, b) => a + b, 0);
    if (products.length > 0) {
        return amountSum / products.length;
    }
    else {
        return 0;
    }
}

const getDefaultProduct = (shopId: CartDtoShopsSelectedEnum) => ({
    id: 'invalid-id',
    name: 'invalid-name',
    shop: shopId,
    description: '',
    shopCategory: 'invalid-category',
    shopSubCategory: 'invalid-sub-category',
    swagalCategory: ProductDtoSwagalCategoryEnum.Sonstiges,
    currentPrice: 0,
    unit: ProductDtoUnitEnum.None,
    amount: 0,
    currentPriceClassification: ProductDtoCurrentPriceClassificationEnum.Normal,
    image: ''
});

export const getRelDiff = (minPrice: number, maxPrice: number): string => {
    const absDiff = maxPrice - minPrice;
    const relDiff = Math.floor(((maxPrice / minPrice) - 1) * 100);
    if (Math.round(absDiff) !== 0 && relDiff === 0) {
        return '<1';
    }
    return `${relDiff}`;
};

// Calculate price/referencePrice for each product
export const calcCartProductStats = (selectedShops: CartDtoShopsSelectedEnum[], cartItemComp: CartItemComparisonDto): CartProductStats => {
    // this is a temporary fix -> bug related to selectedShops and shopsSelected in response not matching
    // TODO: implement properly!!!
    const filteredProducts: ProductDto[] = selectedShops
        .flatMap(shopId => cartItemComp.products.find(item => item.shop === shopId) ?? getDefaultProduct(shopId));

    const unitsMatch = filteredProducts
        .map(product => product.unit)
        .every((value, index, array) => value !== ProductDtoUnitEnum.None && value === array[0]);

    // TODO: define for every product?
    const referenceAmount = unitsMatch ? getReferenceAmount(filteredProducts) : null;
    const prices = filteredProducts.map(getPrice(unitsMatch, referenceAmount));
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);

    const productShopPrices: UIProductPrice[] = filteredProducts
        .map((itemInShop, index) => ({
            price: prices[index],
            shopId: itemInShop.shop,
            leastExpensive: prices[index] === minPrice
        }));

    const absDiff = maxPrice - minPrice;
    const relDiff = getRelDiff(minPrice, maxPrice);
    // ^current definition: "most expensive is X% more expensive than cheapest"

    return {
        productShopPrices,
        absDiff,
        relDiff,
        referenceAmount,
        unit: filteredProducts[0].unit
    }
};

// ----------------------------------------------------------------------------

export interface CartCategoryStatistic {
    accPrice: number,
    leastExpensive: boolean
}

export interface CartCategoryStats {
    categoryShopPrices: CartCategoryStatistic[],
    absDiff: number,
    relDiff: string
}

// Calculate accumulated price/referencePrice for each category
export const calcCartCategoryStats = (category: CartCategoryDto, selectedShops: CartDtoShopsSelectedEnum[]): CartCategoryStats => {
    const cartItemStats = category.cartItemComparisons
        .filter(cartItemComp => cartItemComp.enabled)
        .map(cartItemComp => calcCartProductStats(selectedShops, cartItemComp));
    const cartCategoryAccPrice = cartItemStats
        .reduce(
            (acc, item) => {
                item.productShopPrices.forEach((productShopPrice, index) => {
                    acc[index] += productShopPrice.price
                });
                return acc
            },
            Array<number>(selectedShops.length).fill(0)
        );

    const minPrice = Math.min(...cartCategoryAccPrice);
    const maxPrice = Math.max(...cartCategoryAccPrice);
    const absDiff = maxPrice - minPrice;
    const relDiff = getRelDiff(minPrice, maxPrice);

    return {
        categoryShopPrices:
            cartCategoryAccPrice
                .map(accPrice => ({
                    accPrice,
                    leastExpensive: accPrice === minPrice
                })),
        absDiff,
        relDiff
    }
};