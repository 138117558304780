import { Box, Breadcrumbs, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../media/Icon_2.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";
import { findCategoryById } from "../../domain/data";
import Hidden from '@mui/material/Hidden';
import { Category } from "../../domain/types";
import { init as initCategorySelection } from "../../features/categoryselect/categoryselectSlice";
import { useDispatch, useSelector } from "react-redux";
import { FilterState } from "../../features/filter/filterSlice";


interface CategoryIconProps {
  icon: string,
  disabled: boolean
}

export const CategoryIcon: React.FC<CategoryIconProps> = (
  props: CategoryIconProps
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const size = isMobile ? "24px" : "32px";

  return (
    <Box sx={{
      backgroundColor: "bgLight.main",
      color: "swagalBlack.main",
      borderRadius: "4px",
      height: size,
      minHeight: size,
      width: size,
      minWidth: size,
      marginRight: "8px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    }}>
      <span
        className="material-symbols-outlined"
        style={{ fontSize: isMobile ? "20px" : "26px" }}
      >
        {props.icon}
      </span>
    </Box>);
};

interface CartCategoryBreadcrumbProps {
  categoryId: string;
}

export const CartCategoryBreadcrumb: React.FC<CartCategoryBreadcrumbProps> = ({
  categoryId,
}) => {
  const category = findCategoryById(categoryId);
  if (category) {
    return (
      <Box>
        <Hidden mdDown>
          <CartCategoryBreadcrumbDesktop cartCategory={category}></CartCategoryBreadcrumbDesktop>
        </Hidden>
        <Hidden mdUp>
          <CartCategoryBreadcrumbMobile cartCategory={category}></CartCategoryBreadcrumbMobile>
        </Hidden>
      </Box>
    );
  } else {
    return <></>;
  }
};

const CartCategoryBreadcrumbDesktop: React.FC<{ cartCategory: Category }> = ({
  cartCategory,
}) => {
  const dispatch = useDispatch();
  const selectedShops = useSelector((state: FilterState) => state.filter.data.selectedShops);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      columnSpacing={2}
      padding={2}
      sx={{ paddingTop: "40px", paddingBottom: "4px" }}
    >
      <Grid md={5 + selectedShops.length} item>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="medium" sx={{ marginLeft: "-12px", marginRight: "-12px", color: "textDisabled.main" }} />}
          >
            <Link color="inherit" to="/" onClick={() => dispatch(initCategorySelection())} style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: "16px",
                }}
              >
                <Box sx={{
                  borderRadius: "4px",
                  height: "24px",
                  width: "24px",
                  minHeight: "24px",
                  minWidth: "24px",
                  marginRight: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  overflow: 'hidden'
                }}>
                  <img src={Logo} height={42} alt="swagal" loading="lazy" />
                </Box>
                <Typography
                  lineHeight="24px"
                  fontSize="20px"
                  fontWeight={400}
                  color="swagalBlack.main"
                >
                  Dein Einkaufskorb
                </Typography>
              </Box>
            </Link>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "16px",
              }}
            >
              <CategoryIcon icon={cartCategory.icon} disabled={false} />

              <Typography
                fontWeight="600"
                fontSize="20px"
                lineHeight="24px"
                sx={{ color: "swagalBlack.main", fontSize: "20px" }}
              >
                {cartCategory.description}
              </Typography>
            </Box>
          </Breadcrumbs>
        </Box>
      </Grid>
    </Grid>
  )
}

const CartCategoryBreadcrumbMobile: React.FC<{ cartCategory: Category }> = ({
  cartCategory,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: "16px"
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" sx={{ color: "textDisabled.main" }} />}
          >
            <Link color="inherit" to="/" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{
                  borderRadius: "4px",
                  height: "24px",
                  width: "24px",
                  minHeight: "24px",
                  minWidth: "24px",
                  marginRight: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  overflow: 'hidden'
                }}>
                  <img src={Logo} height={42} alt="swagal" loading="lazy" />
                </Box>
                <Typography
                  fontSize="14px"
                  lineHeight="24px"
                  color="swagalBlack.main"
                >
                  Dein Einkaufskorb
                </Typography>
              </Box>
            </Link>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CategoryIcon icon={cartCategory.icon} disabled={false} />

              <Typography
                fontWeight="600"
                fontSize="14px"
                color="swagalBlack.main"
              >
                {cartCategory.description}
              </Typography>
            </Box>
          </Breadcrumbs>
        </Box>
      </Grid>
    </Grid>
  )
}