import { Box, Skeleton } from "@mui/material";
import { CartCategory } from "./CartCategory";
import CartHeader from "./CartHeader";
import { useDispatch, useSelector } from "react-redux";
import { init } from "../../features/categoryselect/categoryselectSlice";
import { CartCategoryDto } from "../../api";
import Hidden from '@mui/material/Hidden';
import { FilterState } from "../../features/filter/filterSlice";
import { useParams } from "react-router-dom";

export default function Cart() {
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  if (categoryId == null || categoryId == undefined) {
    dispatch(init())
  }
  return (
    <Box>
      <Hidden mdDown>
        <CartDesktop></CartDesktop>
      </Hidden>
      <Hidden mdUp>
        <CartMobile></CartMobile>
      </Hidden>
    </Box>
  );
}

const CartDesktop = () => {
  const { categories } = useSelector((state: any) => state.cartData.data);
  const selectedShops = useSelector((state: FilterState) => state.filter.data.selectedShops);

  return (
    <Box sx={{ paddingTop: "32px", marginTop: "148px", paddingBottom: "86px" }}>
      <CartHeader></CartHeader>
      {
        categories.map((category: CartCategoryDto, index: number) => (
          <CartCategory
            key={index}
            category={category}
            selectedShops={selectedShops}
            isLast={index === (categories.length - 1)}
          ></CartCategory>
        ))
      }
    </Box>
  )
}

const CartMobile = () => {
  const cartDataState = useSelector((state: any) => state.cartData.data);
  const { categories } = cartDataState;
  const selectedShops = useSelector((state: FilterState) => state.filter.data.selectedShops);

  return (
    <Box color="bgLight" sx={{ backgroundColor: "bgLight.main", paddingTop: "16px", paddingBottom: "104px" }}>
      {
        categories.length === 0 ?
          (
            <CardSkeletons></CardSkeletons>
          ) :
          (
            categories.map((category: CartCategoryDto, index: number) => (
              <CartCategory
                key={index}
                category={category}
                selectedShops={selectedShops}
                isLast={index === (categories.length - 1)}
              ></CartCategory>
            ))
          )
      }
    </Box>
  )
}

const CardSkeletons = () => {
  return (
    <Box>
      <CardSkeleton></CardSkeleton>
      <CardSkeleton></CardSkeleton>
      <CardSkeleton></CardSkeleton>
      <CardSkeleton></CardSkeleton>
      <CardSkeleton></CardSkeleton>
    </Box>
  )
}

const CardSkeleton = () => {
  return (
    <Skeleton variant="rectangular"
      sx={{
        boxShadow: "0px -1px 10px 0px #0000001A",
        borderRadius: "16px",
        marginBottom: "16px",
        marginLeft: "16px",
        marginRight: "16px"
      }} height="290px" />
  )
}
