import { Box, Grid, IconButton, Typography, Chip, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  CartCategoryDto,
  CartDtoShopsSelectedEnum,
} from "../../api";
import { findCategoryById, shops } from "../../domain/data";
import { useDispatch, useSelector } from "react-redux";
import { cartDataChange } from "../../features/cart/cartDataSlice";
import { changeCategoryTo } from "../../features/categoryselect/categoryselectSlice";
import Hidden from '@mui/material/Hidden';
import { calcCartCategoryStats } from "./CartProductStats";
import { FilterState } from "../../features/filter/filterSlice";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CartDivider } from "./CartDivider";
import { CartVerticalDivider } from "./CartVerticalDivider";
import { PriceChip } from "./PriceChip";


interface CartCategoryProps {
  category: CartCategoryDto;
  selectedShops: CartDtoShopsSelectedEnum[];
  isLast: boolean;
}


const joinShortProductNames = (shortProductNames: string[]) => {
  const fixedChars = 30
  if (shortProductNames.length === 0) return "-"
  const allShortNamesJoined = shortProductNames.join(', ')

  // returns joined product names in case string is short enough
  if (allShortNamesJoined.length <= fixedChars) {
    return allShortNamesJoined
  }

  // print only first few products and add eg.: `+ 4`
  const lastSeparatorIndexAfterFixedChar = allShortNamesJoined.substring(0, fixedChars).lastIndexOf(',')
  const displayableShortNames = allShortNamesJoined.substring(0, lastSeparatorIndexAfterFixedChar)
  const numberToAdd = allShortNamesJoined.substring(lastSeparatorIndexAfterFixedChar + 1, allShortNamesJoined.length).split(',').length - 1
  if (numberToAdd === 0) {
    return displayableShortNames
  }
  return `${displayableShortNames}, + ${numberToAdd}`
}


interface CategoryIconProps {
  icon: string,
  disabled: boolean
}

export const CategoryIcon: React.FC<CategoryIconProps> = (
  props: CategoryIconProps
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const enabledCategoryTextStyle = "swagalBlack.main";
  const disabledCategoryTextStyle = "textDisabled.main";
  const categoryTextStyle = !props.disabled
    ? enabledCategoryTextStyle
    : disabledCategoryTextStyle;

  const size = isMobile ? "48px" : "40px";

  return (
    <Box sx={{
      backgroundColor: isMobile ? "bgDefault.main" : "bgLight.main",
      color: categoryTextStyle,
      borderRadius: isMobile ? "4px" : "2px",
      height: size,
      minHeight: size,
      width: size,
      minWidth: size,
      marginRight: "16px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    }}>
      <span
        className="material-symbols-outlined"
        style={{ fontSize: isMobile ? "39px" : "32px" }}
      >
        {props.icon}
      </span>
    </Box>);
};



export const CartCategory: React.FC<CartCategoryProps> = (
  props: CartCategoryProps
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const category = findCategoryById(props.category.name);

  const navigateToCategory = (category: string) => {
    navigate("/" + findCategoryById(category)?.urlId);
    dispatch(changeCategoryTo(category));
  };

  const selectedShops = useSelector((state: FilterState) => state.filter).data.selectedShops;

  const cartCategoryStats = calcCartCategoryStats(props.category, selectedShops);

  const categoryEmpty = props.category.cartItemComparisons
    .filter(cartItemComp => cartItemComp.enabled)
    .length === 0;

  const theme = useTheme();
  const categoryTextColor = (props.category.enabled && !categoryEmpty)
    ? theme.palette.swagalBlack.main
    : theme.palette.textDisabled.main;

  return (
    <Box>
      <Hidden mdDown>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          columnSpacing={2}
          padding={2}
        >
          <Grid md={3} item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <CategoryIcon icon={category?.icon ?? ""} disabled={!props.category.enabled || categoryEmpty} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  color="inherit"
                  onClick={() => navigateToCategory(category?.id as string)}
                  style={{ textDecoration: "none", color: "swagalBlack", cursor: "pointer" }}
                >
                  <Typography
                    marginTop="4px"
                    fontSize={16}
                    fontWeight={600}
                    color="swagalBlack"
                    sx={{
                      textDecoration: "underline",
                      color: categoryTextColor,
                    }}
                  >
                    {findCategoryById(props.category.name)?.description}
                  </Typography>
                </Box>
                <Typography
                  marginTop="0px"
                  fontSize={14}
                  sx={{ color: categoryTextColor }}
                >
                  {joinShortProductNames(props.category.cartItemComparisons.map(p => p.description))}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {cartCategoryStats.categoryShopPrices.map((cartCategoryStatistic, index: number) => (
            <Grid key={index} md={1} item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {categoryEmpty
                  ? <span style={{ color: categoryTextColor }}>-</span>
                  : <PriceChip
                      price={cartCategoryStatistic.accPrice}
                      highlight={cartCategoryStatistic.leastExpensive}
                      disabled={!props.category.enabled}
                    />
                }
              </Box>
            </Grid>
          ))}
          <CartVerticalDivider md={1} />
          <Grid md={1} item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
            {categoryEmpty
              ? <span style={{ color: categoryTextColor}}>-</span>
              : (Math.round(cartCategoryStats.absDiff) === 0
                  ? <span style={{
                      fontSize: "18px",
                      lineHeight: "24px",
                      color: categoryTextColor
                    }} className="material-symbols-outlined">
                      equal
                    </span>
                  : <>
                    <Typography
                      fontSize={16}
                      lineHeight="24px"
                      sx={{ color: categoryTextColor }}
                    >
                      {cartCategoryStats.absDiff.toSwagalAmount()}
                    </Typography>
                    <Typography
                      fontSize={14}
                      lineHeight="24px"
                      sx={{ color: categoryTextColor }}
                    >
                      {cartCategoryStats.relDiff}%
                    </Typography>
                  </>
                )}
            </Box>
          </Grid>
          <Grid md={1} item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              {categoryEmpty
                ? <IconButton
                    aria-label="info"
                    color={"swagalBlack"}
                    sx={{ marginRight: "-8px" }}
                  >
                    <Tooltip title="Diese Kategorie enthält keine vergleichbaren Produkte.">
                      <span className="material-symbols-outlined">info</span>
                    </Tooltip>
                  </IconButton>
              : <IconButton
                  aria-label="delete"
                  onClick={() => dispatch(cartDataChange(props.category.name))}
                  color={"swagalBlack"}
                  sx={{ marginRight: "-8px" }}
                >
                  <span className="material-symbols-outlined">
                    {props.category.enabled === true
                        ? 'visibility'
                        : 'visibility_off'}
                  </span>
                </IconButton>
              }
            </Box>
          </Grid>
        </Grid>

        {props.isLast || <CartDivider md={6 + selectedShops.length} />}
      </Hidden>

      <Hidden mdUp>
        <Box
          justifyContent="flex-start"
          alignItems="center"
          borderRadius="16px"
          sx={{
            boxShadow: "0px -1px 10px 0px #0000001A",
            backgroundColor: "swagalWhite.main",
            marginBottom: "16px",
            marginLeft: "16px",
            marginRight: "16px"
          }}
        >
          <Box sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: "24px",
            paddingBottom: "24px",
            borderBottom: "1px solid",
            borderBottomColor: "bgDefault.main"
          }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative"
              }}
            >
              <CategoryIcon icon={category?.icon ?? ""} disabled={!props.category.enabled || categoryEmpty} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  color="inherit"
                  onClick={() => navigateToCategory(category?.id as string)}
                  style={{ textDecoration: "none", color: "swagalBlack" }}
                >
                  <Typography
                    fontSize={16}
                    fontWeight={600}
                    lineHeight={"24px"}
                    color="swagalBlack"
                    sx={{
                      textDecoration: "underline",
                      color: categoryTextColor,
                    }}
                  >
                    {findCategoryById(props.category.name)?.description}
                  </Typography>
                </Box>
                <Typography
                  fontSize={14}
                  fontWeight={400}
                  lineHeight={"24px"}
                  sx={{ color: categoryTextColor }}
                >
                  {joinShortProductNames(props.category.cartItemComparisons.map(p => p.description))}
                </Typography>
              </Box>
              <Box sx={{ position: "absolute", right: "0px" }}>
                <IconButton
                  aria-label="delete"
                  onClick={categoryEmpty
                    ? () => {}
                    : () => dispatch(cartDataChange(props.category.name))
                  }
                  color={categoryEmpty ? "textDisabled" : "swagalBlack"}
                  sx={{ padding: 0 }}
                >
                  <span style={{ fontSize: "28px" }} className="material-symbols-outlined">
                    {props.category.enabled === true
                      ? 'visibility'
                      : 'visibility_off'}
                  </span>
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Box>
            <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {categoryEmpty
                ? <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "12px",
                      marginBottom: "12px",
                      marginLeft: "24px",
                      marginRight: "24px"
                    }}
                  >
                    <span style={{ fontSize: "28px", marginRight: "12px" }} className="material-symbols-outlined">info</span>
                    <Typography sx={{ lineHeight: "20px", fontSize: "14px" }}>Diese Kategorie enthält keine vergleichbaren Produkte.</Typography>
                  </Box>
                : (cartCategoryStats.categoryShopPrices.map((cartCategoryStatistic, index) => {
                    const currentShop = selectedShops[index];
                    return (
                      <Grid xs={5.7} item key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "12px",
                            marginBottom: "12px"
                          }}
                        >
                          {
                            cartCategoryStatistic.leastExpensive ?
                              (<><Box sx={{ backgroundColor: "teal20.main", borderRadius: "4px 0px 0px 4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img
                                  src={shops[currentShop].logo as string}
                                  style={{
                                    maxHeight: "40px",
                                    maxWidth: "40px",
                                    margin: "auto",
                                    opacity: props.category.enabled ? 1.0 : 0.2
                                  }}
                                  alt="swagal"
                                  loading="lazy"
                                />
                              </Box>
                                <Box sx={{ backgroundColor: "teal10.main", borderRadius: "0px 4px 4px 0px", padding: "4px", width: "90px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  {/* width needs to be larger than in Figma to accomodate prices above 99€ (88px needed) ------------^ */}
                                  <Typography
                                    fontSize={20}
                                    fontWeight={500}
                                    align="center"
                                    sx={{ color: categoryTextColor }}
                                  >
                                    {cartCategoryStatistic.accPrice.toSwagalAmount()}
                                  </Typography>
                                </Box></>) : (<><Box sx={{ backgroundColor: "bgLight.main", borderRadius: "4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  <img
                                    src={shops[currentShop].logo as string}
                                    style={{
                                      maxHeight: "40px",
                                      maxWidth: "40px",
                                      margin: "auto",
                                      opacity: props.category.enabled ? 1.0 : 0.2
                                    }}
                                    alt="swagal"
                                    loading="lazy"
                                  />
                                </Box>
                                  <Box sx={{ backgroundColor: "swagalWhite.main", borderRadius: "4px", padding: "4px", width: "90px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography
                                      fontSize={20}
                                      fontWeight={400}
                                      align="center"
                                      sx={{ color: categoryTextColor }}
                                    >
                                      {cartCategoryStatistic.accPrice.toSwagalAmount()}
                                    </Typography>
                                  </Box></>)
                          }
                        </Box>
                      </Grid>
                    );
                  })
              )}
            </Grid>

          </Box>

          {(!categoryEmpty && Math.round(cartCategoryStats.absDiff) !== 0) && <Box
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingTop: "12px",
              paddingBottom: "12px",
              borderTop: "1px solid",
              borderTopColor: "bgDefault.main",
              display: "flex",
              flexDirextion: "row",
              flexFlow: "space-between"
            }}>
            <Typography
              fontSize={14}
              fontWeight={500}
              lineHeight={"24px"}
              sx={{ color: categoryTextColor, flexGrow: 1 }}
            >
              Diff € / %
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={400}
              lineHeight={"24px"}
              align="right"
              sx={{ color: categoryTextColor, flexGrow: 1 }}
            >
              {cartCategoryStats.absDiff.toSwagalAmount()} / {cartCategoryStats.relDiff}%
            </Typography>
          </Box>
          }
        </Box>
      </Hidden>
    </Box>
  );
};
