import { useTheme } from '@mui/material/styles';
import { Divider, Grid } from "@mui/material";

interface CartDividerProps {
    md: number
}

export const CartDivider: React.FC<CartDividerProps> = (props: CartDividerProps) => {
    const theme = useTheme();
    const bgDefault = theme.palette.bgDefault.main;
    const invis = theme.palette.invis.main;

    return (
        <Grid
            container
            justifyContent="center"
            columnSpacing={2}
            paddingLeft={2}
            paddingRight={2}
        >
            <Grid md={props.md} item>
                <div style={{
                    borderBottom: "1px solid",
                    borderColor: bgDefault,
                    height: "0px",
                    width: "100%",
                    backgroundColor: invis
                }}/>
            </Grid>
        </Grid>);
};