import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { Dialog, Hidden, StepIcon } from '@mui/material';
import Logo from "../media/Icon_2.svg";
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { closeIntro } from '../features/intro/introSlice';

const steps = [
  {
    label: 'Was ist sWagal?',
    description: `sWagal vergleicht 100 beliebte Lebensmittel von Billa, Hofer und Spar.`,
  },
  {
    label: 'Warum soll ich sWagal nutzen?',
    description:
      'Mit sWagal bekommst du einen Überblick, wie viel ein für dich zusammengestellter Warenkorb bei Billa, Hofer und Spar kostet. Und nicht nur das: Du kannst nach Preis, Herkunft und Qualität filtern, um immer das beste Angebot zu finden. Darüberhinaus steht dir eine Produktsuche mit Preisentwicklung zur Verfügung.',
  },
  {
    label: 'Was wird verglichen?',
    description:
      'Wir haben für dich 100 Artikel identifiziert, die häufig in österreichischen Wagerl liegen und für einen Vergleich aufbereitet.',
  },
  {
    label: 'Worauf solltest du achten?',
    description: `Wir nutzen Online-Preise und machen sie durch Normalisierung von Gewichts- und Mengenangaben vergleichbar. So wollen wir faire und transparente Vergleiche ermöglichen.`,
  },
  {
    label: 'Wie kannst du das Projekt unterstützen?',
    description: `Wir stehen am Anfang einer Reise und freuen uns auf deine Meinung. Hilf uns, indem du uns deine Eindrücke und Anregungen über den Button "SWAGAL BEWERTEN" mitteilst. Hinweis: Preisirrtümer stets vorbehalten. Markennamen und -bilder sind Eigentum der jeweiligen Inhaber.`,
  }
];

export const IntroDesktop: React.FC<{ open: string }> = ({
  open
}: { open: string }) => {
  const dispatch = useDispatch();
  const handleCloseDialog = () => {
    dispatch(closeIntro())
  }
  return (
    <Hidden lgDown>
      <Dialog onClose={handleCloseDialog} open={open !== "false"} sx={{ padding: "0px" }}>
        <Box sx={{ minWidth: "500px", backgroundColor: 'swagalTeal.main', padding: "32px", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <IntroContent></IntroContent>
        </Box>
      </Dialog>
    </Hidden>
  )
}


export const IntroMobile: React.FC<{ open: string }> = ({
  open
}: { open: string }) => {
  if (open === "false") {
    return (<></>)
  }

  return (
    <Hidden lgUp>
      <Box sx={{ backgroundColor: 'swagalTeal.main', height: "100vh", display: "flex", alignItems: "center", flexDirection: "column" }} >
        <IntroContent></IntroContent>
      </Box>
    </Hidden>
  );
}


const IntroContent = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const dispatch = useDispatch();
  const handleCloseDisclaimer = () => {
    dispatch(closeIntro())
  }

  return (
    <Box>
      <Box sx={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <img src={Logo} height={150} alt="swagal" loading="lazy" />
      </Box>
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical" sx={{ marginLeft: "32px", marginRight: "32px", marginBottom: "32px" }}>
          {steps.map((step, index) => (
            <Step key={step.label} sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'swagalWhite.main', // circle color (COMPLETED)
                borderColor: 'white'
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'grey.500', // Just text label (COMPLETED)
              }, 
              '& .MuiStepLabel-label.Mui-disabled':
              {
                color: 'rgb(224 224 224 / 60%)', // Just text label (COMPLETED)
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: 'swagalWhite.main', // circle color (ACTIVE)

              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
              {
                fill: '#54A492',
                color: '#54A492'
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: '#54A492', // circle's number (ACTIVE)
                color: '#54A492'
              },
              '& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-text': {
                fill: 'teal20.main', // circle's number (ACTIVE)
              },
              '& .MuiTypography-root MuiTypography-caption': {

              },
              '& .MuiStepIcon-root': {
                fill: 'rgb(224 224 224 / 30%)',
                color: 'rgb(224 224 224 / 30%)'
              },
              '& .MuiStepIcon-root.Mui-active': {
                fill: 'white', // circle's number (ACTIVE)
                color: 'rgb(224 224 224 / 60%)'
              },
              '& .MuiStepIcon-root.Mui-completed': {
                fill: 'white',
                color: 'white'
              },
            }}>
              <StepLabel>
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography color="swagalWhite.main" fontSize={14}>{step.description}</Typography>
                <Box sx={{ mb: 2, mt: 2 }}>
                  <div>
                    <Button
                      disableElevation
                      variant="contained"
                      color="swagalWhite"
                      onClick={handleNext}
                      sx={{ color: "swagalTeal.main", mr: 2 }}
                    >
                      {index === steps.length - 1 ? <Typography color="swagalTeal.main" sx={{ fontSize: "12px", fontWeight: "500" }} >Fertig</Typography> : <Typography sx={{ fontSize: "12px", fontWeight: "500" }} color="swagalTeal.main">Weiter</Typography>}
                    </Button>
                    {index !== 0 ? (
                      <Button
                        disableElevation
                        disabled={index === 0}
                        onClick={handleBack}
                        color="swagalWhite"
                        sx={{ color: "swagalBlack.main" }}
                      >
                        <Typography sx={{ fontSize: "12px", fontWeight: "400" }} color="swagalWhite.main">Zurück</Typography>
                      </Button>
                    ) : (<></>)}
                  </div>
                </Box>
              </StepContent>
              <StepIcon icon={undefined} sx={{ backgroundColor: 'teal20.main', color: 'teal20.main' }}></StepIcon>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Button variant="contained"
              color="swagalTeal" disableElevation sx={{ color: "swagalWhite.main", pl: "12px", pr: "12px" }} endIcon={<EastOutlinedIcon sx={{ color: 'swagalWhite.main' }} />} onClick={handleCloseDisclaimer} >
              <Typography sx={{ fontSize: "16px", fontWeight: "500" }} color="swagalWhite.main">Starten</Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}