import { Chip, Typography } from "@mui/material";

interface PriceChipProps {
    price: number,
    highlight: boolean
    disabled: boolean
}

export const PriceChip: React.FC<PriceChipProps> = (
    props: PriceChipProps
) => {
    const enabledCategoryTextStyle = "swagalBlack.main";
    const disabledCategoryTextStyle = "textDisabled.main";
    const categoryTextStyle = !props.disabled
        ? enabledCategoryTextStyle
        : disabledCategoryTextStyle;

    let highlightColor = "invis.main";
    if (props.highlight) {
        highlightColor = props.disabled ? "teal10.main" : "teal20.main";
    }

    return (
        <Chip
            sx={{ borderRadius: "2px", height: "40px", width: "88px", backgroundColor: highlightColor }}
            label={
                <Typography
                    fontSize={16}
                    lineHeight="24px"
                    align="center"
                    sx={{ color: categoryTextStyle, fontWeight: props.highlight ? "700" : "regular" }}
                >
                    {props.price.toSwagalAmount()}
                </Typography>}
        />
    );
};
