import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {
  Box,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import { shops } from "../domain/data";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterState,
  applyTmpToFinalFilter,
} from "../features/filter/filterSlice";
import { fetchCart, fetchProducts } from "../async/actions";
import Hidden from '@mui/material/Hidden';
import FeedbackDialogEntrypoint from "./Feedback";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetSearch } from "../features/search/searchSlice";
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';


import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from '@mui/material/Paper';
import { CartDtoShopsSelectedEnum } from "../api/api";

const emails = ["username@gmail.com", "user02@gmail.com"];

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const StyledToggleButtonGroupDesktop = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(1),
    borderWidth: 1.5,
    borderStyle: "solid",
    borderColor: "white",
    width: "100px",
    height: "60px",
    backgroundColor: "#FAFAFA",
    '&.Mui-selected:hover': {
      backgroundColor: "#54A4921A",
      borderWidth: 1.5,
      borderStyle: "solid",
      borderColor: "#54A492",
    },
    '&.Mui-selected': {
      backgroundColor: "#54A4921A",
      borderWidth: 1.5,
      borderStyle: "solid",
      borderColor: "#54A492",
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButtonGroupMobile = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(1),
    borderWidth: 1.5,
    borderStyle: "solid",
    borderColor: "white",
    width: "140px",
    height: "80px",
    backgroundColor: "#FAFAFA",
    '&.Mui-selected:hover': {
      backgroundColor: "#54A4921A",
      borderWidth: 1.5,
      borderStyle: "solid",
      borderColor: "#54A492",
    },
    '&.Mui-selected': {
      backgroundColor: "#54A4921A",
      borderWidth: 1.5,
      borderStyle: "solid",
      borderColor: "#54A492",
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function FilterDialog(props: SimpleDialogProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { onClose, selectedValue, open } = props;

  const preference = useSelector((state: FilterState) => state.filter.data.preference);

  const selectedShops = useSelector((state: FilterState) => state.filter.data.selectedShops);

  const [currentSelectedShops, setCurrentSelectedShops] = React.useState(() => selectedShops);
  const handleSelectedShopsChange = (event: React.MouseEvent<HTMLElement>, newSelectedShops: CartDtoShopsSelectedEnum[]) => {
    setCurrentSelectedShops(newSelectedShops);
  };

  const [currentPrefernece, setCurrentPreference] = React.useState(() => preference);
  const handlePreferenceChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCurrentPreference(value);
  };

  const handleClose = () => {
    setCurrentSelectedShops(selectedShops)
    setCurrentPreference(preference)
    onClose(selectedValue);
  };

  const handleApply = () => {
    dispatch(applyTmpToFinalFilter({ selectedShops: currentSelectedShops, preference: currentPrefernece }));
    if (location.pathname.includes("products")) {
      dispatch(resetSearch())
      dispatch(fetchProducts())
    } else {
      dispatch(fetchCart());
    }

    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} sx={{ padding: "0px" }}>
      <DialogTitle sx={{
        display: "flex", justifyContent: "center",
        alignItems: "center"
      }}>Filter</DialogTitle>
      <FormControl>
        <Box sx={{ paddingLeft: "32px", paddingRight: "32px" }}>

          <Typography marginTop="8px" fontSize={20} color="swagalBlack.main">
            Supermarktketten auswählen
          </Typography>
          <Typography marginTop="8px" fontSize={16} color="textSecondary.main">
            Welche Supermarktketten möchtest du vergleichen?
          </Typography>
          <Box sx={{
            marginTop: "32px", marginBottom: "32px", display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            {
              Object.values(shops).map((shop, index) => (
                <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
                  <StyledToggleButtonGroupDesktop
                    size="small"
                    value={currentSelectedShops}
                    onChange={handleSelectedShopsChange}
                    aria-label="text formatting"
                  >
                    <ToggleButton key={shop.id as string} value={shop.id}>
                      <img
                        src={shop.logo as string}
                        style={{ maxHeight: "40px", maxWidth: "80px" }}
                        alt={shop.id as string}
                        loading="lazy"
                      />
                    </ToggleButton>
                  </StyledToggleButtonGroupDesktop>
                  <Typography fontSize={14} fontWeight={currentSelectedShops.includes(shop.id) ? 500 : 400} lineHeight="24px" sx={{ marginLeft: "8px" }}>{shop.description}</Typography>
                </Box>
              ))
            }
          </Box>

          <Divider></Divider>

          <Box sx={{ paddingTop: "32px", paddingBottom: "32px" }}>
            <Typography marginTop="8px" fontSize={20} color="swagalBlack.main">
              Präferenz auswählen
            </Typography>
            <Typography marginTop="8px" fontSize={16} color="textSecondary.main">
              Was ist dir beim Einkauf am Wichtigsten?
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={currentPrefernece}
              onChange={handlePreferenceChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="PRICE"
                control={<Radio color="swagalTeal" />}
                label="Preis"
              />
              <FormControlLabel
                value="REGION"
                control={<Radio color="swagalTeal" />}
                label="Herkunft Österreich"
              />
              <FormControlLabel
                value="BIO"
                control={<Radio color="swagalTeal" />}
                label="Bio"
              />
            </RadioGroup>
          </Box>
          {/*
          <Divider></Divider>
          <Box sx={{ paddingTop: "32px", paddingBottom: "32px" }}>
            <Typography marginTop="8px" fontSize={20} color="swagalBlack.main">
              Rabatte & Aktionen
            </Typography>
            <Typography
              marginTop="8px"
              fontSize={16}
              color="textSecondary.main"
            >
              Welche Rabatte & Aktionen sollen wir für dich berücksichtigen?
            </Typography>
            <FormControlLabel
              control={<Checkbox color="swagalTeal" defaultChecked />}
              label="25% Pickerl (Billa & Spar)"
            />
          </Box>
                */}
        </Box>
        <Divider></Divider>
        <DialogActions
          sx={{
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingRight: "24px",
            margin: "0px",
          }}
        >
          <Button
            sx={{
              backgroundColor: "swagalWhite.main",
              height: "40px",
              fontWeight: "600",
              padding: "8px, 16px, 8px, 16px",
              fontSize: "14px",
            }}
            color="swagalBlack"
            onClick={handleClose}
          >
            AUSWAHL AUFHEBEN
          </Button>
          <Button
            sx={{
              color: "swagalWhite.main",
              height: "40px",
              fontWeight: "600",
            }}
            color="swagalTeal"
            variant="contained"
            disableElevation
            onClick={handleApply}
            disabled={(currentPrefernece == preference && sameMembers(currentSelectedShops, selectedShops))}
          >
            AUSWAHL ÜBERNEHMEN
          </Button>
        </DialogActions>
      </FormControl>
    </Dialog>
  );
}

export default function FilterDialogEntrypoint() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const preference = useSelector((state: FilterState) => state.filter.data.preference);

  const selectedShops = useSelector((state: FilterState) => state.filter.data.selectedShops);

  const [currentSelectedShops, setCurrentSelectedShops] = React.useState(() => selectedShops);
  const handleSelectedShopsChange = (event: React.MouseEvent<HTMLElement>, newSelectedShops: CartDtoShopsSelectedEnum[]) => {
    setCurrentSelectedShops(newSelectedShops);
  };

  const [currentPrefernece, setCurrentPreference] = React.useState(() => preference);
  const handlePreferenceChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCurrentPreference(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentSelectedShops(selectedShops)
    setCurrentPreference(preference)
    setOpen(false);
  };

  const handleApply = () => {
    dispatch(applyTmpToFinalFilter({ selectedShops: currentSelectedShops, preference: currentPrefernece }));
    if (location.pathname.includes("products")) {
      dispatch(resetSearch())
      dispatch(fetchProducts())
    } else {
      dispatch(fetchCart());
    }
    setOpen(false);
  };

  return (
    <Box>
      <Hidden mdDown>
        <Button
          variant="contained"
          color="bgDefault"
          onClick={handleOpen}
          startIcon={<span className="material-symbols-outlined" style={{ fontSize: "20px" }}>page_info</span>}
          disableElevation
        >
          <Typography color="swagalBlack.main" fontWeight="bold" fontSize="14px">
            FILTER
          </Typography>
        </Button>
        <FilterDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Hidden>
      <Hidden mdUp>


        <FilterDialogEntrypointNew></FilterDialogEntrypointNew>
      </Hidden>

    </Box>
  );
}


const Root = styled('div')(({ theme }) => ({
  height: '100%'
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  position: 'absolute',
  top: 8,
  borderRadius: "6px",
  left: 'calc(50% - 15px)',
}));

function FilterDialogEntrypointNew() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const preference: String = useSelector(
    (state: { filter: { data: { selectedShops: String[], preference: String } } }) => state.filter.data.preference
  );

  const selectedShops: String[] = useSelector(
    (state: { filter: { data: { selectedShops: String[], preference: String } } }) => state.filter.data.selectedShops
  );

  const [currentSelectedShops, setCurrentSelectedShops] = React.useState(() => selectedShops);
  const handleSelectedShopsChange = (event: React.MouseEvent<HTMLElement>, newSelectedShops: string[]) => {
    setCurrentSelectedShops(newSelectedShops);
  };

  const [currentPrefernece, setCurrentPreference] = React.useState(() => preference);
  const handlePreferenceChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCurrentPreference(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentSelectedShops(selectedShops)
    setCurrentPreference(preference)
    setOpen(false);
  };

  const handleApply = () => {
    dispatch(applyTmpToFinalFilter({ selectedShops: currentSelectedShops, preference: currentPrefernece }));
    if (location.pathname.includes("products")) {
      dispatch(resetSearch())
      dispatch(fetchProducts())
    } else {
      dispatch(fetchCart());
    }
    setOpen(false);
  };

  const handleNavigateToImpressum = () => {
    handleClose()
    navigate("/impressum")
  }

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100% - ${40}px)`,
            overflow: 'visible',
            borderRadius: "24px"
          },
        }}
      />
      <IconButton sx={{ backgroundColor: "bgDefault.main", color: "swagalBlack.main", borderRadius: "4px", height: "48px", width: "48px" }} onClick={handleOpen}>
        <span className="material-symbols-outlined" style={{ fontSize: "24px" }}>page_info</span>
      </IconButton>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: 1300 }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: 0,
            paddingTop: "24px",
            right: 0,
            left: 0,
            zIndex: 'inherit'
          }}
        >
          <Puller />
          <Box sx={{
            paddingTop: "16px",
            paddingBottom: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid black",
            borderColor: "bgDefault.main"
          }}>
            <Typography fontWeight={600} fontSize={16}>Filter</Typography>
          </Box>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            marginTop: "32px",
            height: '100%',
            overflow: 'auto',
            position: "relative"
          }}
        >
          <Box sx={{ marginTop: "60px", marginBottom: "48px", paddingLeft: "32px", paddingRight: "32px" }}>
            <Box sx={{ paddingTop: "16px" }}>
              <FeedbackDialogEntrypoint></FeedbackDialogEntrypoint>
              <Button
                variant="outlined"
                sx={{ width: "100%", marginTop: "12px" }}
                color="swagalBlack"
                onClick={handleNavigateToImpressum}
              >
                <Typography>IMPRESSUM</Typography>
              </Button>
            </Box>
            <Box sx={{ paddingTop: "16px" }}>
              <Typography marginTop="8px" fontSize={20} fontWeight={600} color="swagalBlack.main">
                Supermarktketten auswählen
              </Typography>
              <Typography marginTop="8px" fontSize={16} color="textSecondary.main">
                Welche Supermarktketten möchtest du vergleichen?
              </Typography>
              <Box sx={{
                display: "flex", flexDirection: "column", justifyContent: "center",
                alignItems: "center", paddingBottom: "32px"
              }}>
                <Box sx={{
                  marginTop: "32px", marginBottom: "4px", display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  {
                    Object.values(shops).slice(0, 2).map((shop, index) => (
                      <Box key={index}>
                        <StyledToggleButtonGroupMobile
                          size="small"
                          value={currentSelectedShops}
                          onChange={handleSelectedShopsChange}
                          aria-label="text formatting"
                        >
                          <ToggleButton key={shop.id as string} value={shop.id}>
                            <img
                              src={shop.logo as string}
                              style={{ maxHeight: "40px", maxWidth: "80px" }}
                              alt={shop.id as string}
                              loading="lazy"
                            />
                          </ToggleButton>
                        </StyledToggleButtonGroupMobile>
                        <Typography fontSize={14} fontWeight={currentSelectedShops.includes(shop.id) ? 500 : 400} lineHeight="24px" sx={{ marginLeft: "8px" }}>{shop.description}</Typography>
                      </Box>
                    ))
                  }
                </Box>
                <Box sx={{
                  marginBottom: "12px", display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  {
                    Object.values(shops).slice(2, 4).map((shop, index) => (
                      <Box key={index}>
                        <StyledToggleButtonGroupMobile
                          size="small"
                          value={currentSelectedShops}
                          onChange={handleSelectedShopsChange}
                          aria-label="text formatting"
                        >
                          <ToggleButton key={shop.id as string} value={shop.id}>
                            <img
                              src={shop.logo as string}
                              style={{ maxHeight: "40px", maxWidth: "80px" }}
                              alt={shop.id as string}
                              loading="lazy"
                            />
                          </ToggleButton>
                        </StyledToggleButtonGroupMobile>
                        <Typography fontSize={14} fontWeight={currentSelectedShops.includes(shop.id) ? 500 : 400} lineHeight="24px" sx={{ marginLeft: "8px" }}>{shop.description}</Typography>
                      </Box>
                    ))
                  }
                </Box>
              </Box>
              <Divider></Divider>
              <Box sx={{ paddingTop: "32px", paddingBottom: "32px" }}>
                <Typography fontSize={20} fontWeight={600} color="swagalBlack.main">
                  Präferenz auswählen
                </Typography>
                <Typography
                  marginTop="8px"
                  fontSize={16}
                  color="textSecondary.main"
                >
                  Was ist dir beim Einkauf am Wichtigsten?
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={currentPrefernece}
                  onChange={handlePreferenceChange}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="PRICE"
                    control={<Radio color="swagalTeal" />}
                    label="Preis"
                  />
                  <FormControlLabel
                    value="REGION"
                    control={<Radio color="swagalTeal" />}
                    label="Herkunft Österreich"
                  />
                  <FormControlLabel
                    value="BIO"
                    control={<Radio color="swagalTeal" />}
                    label="Bio"
                  />
                </RadioGroup>
              </Box>
              {/*
                    <Divider></Divider>
                    <Box sx={{ paddingTop: "32px", paddingBottom: "32px" }}>
                      <Typography fontSize={20} fontWeight={600} color="swagalBlack.main">
                        Rabatte & Aktionen
                      </Typography>
                      <Typography
                        marginTop="8px"
                        fontSize={16}
                        color="textSecondary.main"
                      >
                        Welche Rabatte & Aktionen sollen wir für dich berücksichtigen?
                      </Typography>
                      <FormControlLabel
                        control={<Checkbox color="swagalTeal" defaultChecked />}
                        label="25% Pickerl (Billa & Spar)"
                      />
                    </Box>
                          */}
            </Box>
          </Box>
        </StyledBox>
        <Box sx={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100%",
          paddingBottom: "16px",
          backgroundColor: "swagalWhite.main",
          boxShadow: "0px -1px 10px 0px #0000001A"
        }}>
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "12px",
          }}>
            <Button
              sx={{
                backgroundColor: "swagalWhite.main",
                height: "40px",
                fontWeight: "600",
                padding: "8px, 16px, 8px, 16px",
                fontSize: "14px",
                marginRight: "4px"
              }}
              color="swagalBlack"
              onClick={handleClose}
            >
              <Typography fontSize={12} fontWeight={600}>AUSWAHL AUFHEBEN</Typography>
            </Button>
            <Button
              sx={{
                color: "swagalWhite.main",
                height: "40px",
                fontWeight: "600",
                marginLeft: "12px"
              }}
              onClick={handleApply}
              color="swagalTeal"
              variant="contained"
              disableElevation
              disabled={(currentPrefernece == preference && sameMembers(currentSelectedShops, selectedShops))}
            >
              <Typography fontSize={12} fontWeight={600}>AUSWAHL ÜBERNEHMEN</Typography>
            </Button>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Root>
  );
}

const containsAll = (arr1: String[], arr2: String[]) =>
  arr2.every(arr2Item => arr1.includes(arr2Item))

const sameMembers = (arr1: String[], arr2: String[]) =>
  containsAll(arr1, arr2) && containsAll(arr2, arr1);