import { createSlice } from '@reduxjs/toolkit';
import { CartDtoShopsSelectedEnum } from '../../api/api';

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    data: {
      selectedShops: ["INTERSPAR", "HOFER", "BILLA"],
      preference: "PRICE"
    }
  },
  reducers: {
      applyTmpToFinalFilter(state, param) {
        state.data.selectedShops = param.payload.selectedShops
        state.data.preference = param.payload.preference
      }
  },
});

export interface FilterState {
  filter: {
    data: {
      selectedShops: CartDtoShopsSelectedEnum[];
      preference: string;
    }
  }
};

export const { applyTmpToFinalFilter } = filterSlice.actions;

export default filterSlice.reducer;
