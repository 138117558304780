import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid, Tooltip, styled, useMediaQuery, useTheme } from "@mui/material";
import Logo from "../../media/Icon_2.svg";
import LogoText from "../../media/Logo_3.svg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Hidden from '@mui/material/Hidden';
import { useRef, useState } from "react";
import { BottomSheet } from 'react-spring-bottom-sheet'
import { CartCategoryDto, CartDtoShopsSelectedEnum } from "../../api/api";
import { calcCartCategoryStats, getRelDiff } from "./CartProductStats";
import { FilterState } from "../../features/filter/filterSlice";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { grey } from "@mui/material/colors";
import CssBaseline from '@mui/material/CssBaseline';
import { Global } from '@emotion/react';
import { findCategoryById, findCategoryByUrlId, shops } from "../../domain/data";
import { CartVerticalDivider } from "./CartVerticalDivider";


export interface CartSummaryStatistic {
  accPrice: number,
  leastExpensive: boolean
}

export interface CartSummaryStats {
  summaryShopPrices: CartSummaryStatistic[],
  absDiff: number,
  relDiff: string
}

export const calcCartSummaryStats = (categories: CartCategoryDto[], selectedShops: CartDtoShopsSelectedEnum[]): CartSummaryStats => {
  const cartCategoryStats = categories
    .filter(category => category.enabled)
    .map(category => calcCartCategoryStats(category, selectedShops));
  const cartSummaryAccPrice = cartCategoryStats
    .reduce(
      (acc, item) => {
        item.categoryShopPrices.forEach((cartCategoryStatistic, index) => {
          acc[index] += cartCategoryStatistic.accPrice
        });
        return acc
      },
      Array<number>(selectedShops.length).fill(0)
    );

  const minPrice = Math.min(...cartSummaryAccPrice);
  const maxPrice = Math.max(...cartSummaryAccPrice);
  const absDiff = maxPrice - minPrice;
  const relDiff = getRelDiff(minPrice, maxPrice);

  return {
    summaryShopPrices:
      cartSummaryAccPrice
        .map(accPrice => ({
          accPrice,
          leastExpensive: accPrice === minPrice
        })),
    absDiff,
    relDiff
  }
};


interface CategoryIconProps {
  icon: string,
  disabled: boolean
}

export const CategoryIcon: React.FC<CategoryIconProps> = (
  props: CategoryIconProps
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const enabledCategoryTextStyle =  "swagalWhite.main";
  const disabledCategoryTextStyle = "textDisabled.main";
  const categoryTextStyle = !props.disabled
    ? enabledCategoryTextStyle
    : disabledCategoryTextStyle;

  const size = isMobile ? "48px" : "40px";

  return (
    <Box sx={{
      backgroundColor: "swagalTeal.main",
      color: categoryTextStyle,
      borderRadius: "4px",
      height: size,
      minHeight: size,
      width: size,
      minWidth: size,
      marginRight: "12px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    }}>
      <span
        className="material-symbols-outlined"
        style={{ fontSize: isMobile ? "39px" : "32px" }}
      >
        {props.icon}
      </span>
    </Box>);
};


const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  position: 'absolute',
  top: 8,
  borderRadius: "6px",
  left: 'calc(50% - 15px)',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? '#ff0000' : '#ff0000', // theme.palette.background.default,
  borderRadius: 0
}));

const drawerBleeding = 104;


export default function CartSummary() {
  const { categoryId } = useParams();
  const selectedCategory = (!!categoryId) ? findCategoryByUrlId(categoryId) : undefined;
  const enabledCategories = useSelector((state: any) => state.cartData.data.categories) as CartCategoryDto[];
  const selectedCategoryProductComparisons = enabledCategories.find(category => !!selectedCategory && category.name === selectedCategory.id);
  const selectedCategoryProductsCount = (!!selectedCategoryProductComparisons)
    ? selectedCategoryProductComparisons.cartItemComparisons.filter(comp => comp.enabled).length
    : 0;

  const isProductComparison = !!categoryId;

  const [isOpen, setOpen] = useState(false);

  const selectedShops = useSelector((state: FilterState) => state.filter.data.selectedShops);

  const accumulatorCategories = (!!selectedCategoryProductComparisons) ? [ selectedCategoryProductComparisons ] : enabledCategories;
  const cartSummaryStats = calcCartSummaryStats(accumulatorCategories, selectedShops);
  const leastExpensiveIndex = cartSummaryStats.summaryShopPrices.findIndex(summaryPrice => summaryPrice.leastExpensive);

  const drawerIconWidth = 16 + 48 + 12 + 16;
  const drawerPriceWidth = isOpen ? 0 : (12 + 48 + 90);
  const drawerReservedWidth = drawerIconWidth + drawerPriceWidth;

  return (
    <Root>
      {/*<CssBaseline />*/}
      <Global
        styles={{
          '.MuiDrawer-root.SummaryDrawer > .MuiPaper-root': {
            height: '218px', // 140 (pricecomp) + 78 (diff € / % + bottom margin bc. iPhone home gesture)
            overflow: 'visible',
          },
        }}
      />

      <Hidden mdDown>
        <Box
          display="flex"
          width="100%"
          bottom={0}
          position="fixed"
          sx={{
            boxShadow: "0px -1px 10px 0px #0000001A",
            backgroundColor: "bgLight.main",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={2}
            padding={2}
          >
            <Grid md={3} item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",

                }}
              >
                {selectedCategory
                  ? <CategoryIcon icon={selectedCategory.icon} disabled={false} />
                  : <Box sx={{
                      borderRadius: "4px",
                      height: "40px",
                      width: "40px",
                      minHeight: "40px",
                      minWidth: "40px",
                      marginRight: "12px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      overflow: 'hidden'
                    }}>
                      <img src={Logo} height={72} alt="swagal" loading="lazy" />
                    </Box>
                }
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography fontSize={20} fontWeight={600} lineHeight="24px">
                    Gesamtsumme {(!!categoryId) && findCategoryByUrlId(categoryId)?.description}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", position: "relative", alignItems: 'center' }}>
                    {!isProductComparison &&
                      <img src={LogoText} height="18px" style={{ marginTop: '2px', marginRight: '6px' }} />}
                    <Typography fontSize={16} lineHeight="24px">
                      {isProductComparison
                        ? `Ausgewählte Produkte (${selectedCategoryProductsCount})`
                        : 'Einkaufskorb'}
                    </Typography>
                    <Tooltip title="Der sWagal Einkaufskorb beinhaltet Produkte des täglichen Bedarfs. Die Produkte im sWagal unterscheiden sich durch die gewählte Präferenz bzw. durch manuelles an und abwählen. Für die Berechnung der Gesamtsumme werden normalisierte Preise verwendet.">
                      <span style={{ fontSize: "16px", marginLeft: "4px", cursor: "pointer" }} className="material-symbols-outlined">
                        info
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {cartSummaryStats.summaryShopPrices.map((summaryPrice, index) => (
              <Grid md={1} item key={index}>
                {summaryPrice.leastExpensive === true ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "12px 16px 12px 16px",
                        backgroundColor: "teal20.main",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography fontSize={20} lineHeight="24px" align="center" fontWeight={600}>
                        {summaryPrice.accPrice.toSwagalAmount()}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize={20} lineHeight="24px" align="center">
                      {summaryPrice.accPrice.toSwagalAmount()}
                    </Typography>
                  </Box>
                )}
              </Grid>
            ))}

            <CartVerticalDivider md={1} />

            <Grid md={1} item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Typography fontSize={16} lineHeight="24px" align="center">
                  {cartSummaryStats.absDiff.toSwagalAmount()}
                </Typography>
                <Typography fontSize="14px" lineHeight="24px" align="center">
                  {cartSummaryStats.relDiff}%
                </Typography>
              </Box>
            </Grid>
            {(!isProductComparison) && <Grid item md={1} />}
            {/*<Grid md={1} item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {categoryId ? (
                  <Button
                    variant="contained"
                    color="swagalTeal"
                    sx={{
                      color: "swagalWhite.main",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                    disableElevation
                  >
                    AUSWAHL ÜBERNEHMEN
                  </Button>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
          */}
          </Grid>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <SwipeableDrawer
          anchor="bottom"
          className="SummaryDrawer"
          open={isOpen}
          onClose={event => setOpen(false)}
          onOpen={event => setOpen(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            visibility: 'visible',
            right: 0,
            left: 0,
            backgroundColor: 'bgTeal.main',
            height: drawerBleeding,
            borderBottom: '1px solid',
            borderBottomColor: 'teal20.main',
            boxShadow: "0px -1px 10px 0px rgba(0, 0, 0, 0.1)"
          }}>
            <Puller sx={{ backgroundColor: 'teal40.main' }} />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              paddingLeft="16px"
              paddingRight="16px"
              paddingTop="19px"
            >
                {selectedCategory
                  ? <CategoryIcon icon={selectedCategory.icon} disabled={false} />
                  : <Box sx={{
                      borderRadius: "4px",
                      height: "48px",
                      width: "48px",
                      marginRight: "12px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      overflow: 'hidden'
                    }}>
                      <img src={Logo} height={80} alt="swagal" loading="lazy" />
                    </Box>
                }

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                }}>
                  <Typography
                    fontSize="20px"
                    fontWeight={600}
                    lineHeight="24px"
                    sx={{
                      maxWidth: `calc(100vw - ${drawerReservedWidth}px)`,
                      transition: 'max-width 0.2s',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {(!!categoryId) ? findCategoryByUrlId(categoryId)?.description : 'Gesamtsumme'}
                  </Typography>
                  {/* TODO: add Info tooltip */}
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '24px'
                  }}>
                    {!isProductComparison &&
                      <img src={LogoText} height="15px" style={{ marginRight: '4px' }} />}
                    <Typography fontSize="14px" fontWeight={400} lineHeight="24px">
                      {isProductComparison
                        ? `Gesamt • Auswahl (${selectedCategoryProductsCount})`
                        : 'Einkaufskorb'}
                    </Typography>
                  </div>
                </Box>

              <div style={{ flexGrow: 1 }}></div>

                {leastExpensiveIndex >= 0 && <>
                  <Box sx={{ opacity: isOpen ? 0 : 1, transition: "opacity 0.2s", backgroundColor: "teal20.main", borderRadius: "4px 0px 0px 4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img
                      src={shops[selectedShops[leastExpensiveIndex]].logo as string}
                      style={{
                        maxHeight: "40px",
                        maxWidth: "40px",
                        margin: "auto"
                      }}
                      alt="swagal"
                      loading="lazy"
                    />
                  </Box>
                  <Box sx={{ opacity: isOpen ? 0 : 1, transition: "opacity 0.2s", backgroundColor: "teal10.main", borderRadius: "0px 4px 4px 0px", padding: "4px", width: "90px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography
                      fontSize={20}
                      fontWeight={500}
                      whiteSpace="nowrap"
                      align="center"
                      sx={{ color: "swagalBlack.main" }}
                    >
                      {cartSummaryStats.summaryShopPrices[leastExpensiveIndex].accPrice.toSwagalAmount()}
                    </Typography>
                  </Box>
                </>}
          </Box>
          </StyledBox>

          <StyledBox sx={{
            visibility: 'visible',
            backgroundColor: 'bgTeal.main',
            borderBottom: '1px solid',
            borderBottomColor: 'teal20.main'
          }}>
            <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
              paddingTop="12px"
            >

              {cartSummaryStats.summaryShopPrices.map((summaryShopPrice, index) => {
                const currentShop = selectedShops[index];
                return (
                  <Grid xs={5.7} item key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "16px"
                      }}
                    >
                      {
                        summaryShopPrice.leastExpensive ? (<>
                          <Box sx={{ backgroundColor: "teal20.main", borderRadius: "4px 0px 0px 4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img
                              src={shops[currentShop].logo as string}
                              style={{
                                maxHeight: "40px",
                                maxWidth: "40px",
                                margin: "auto",
                              }}
                              alt="swagal"
                              loading="lazy"
                            />
                          </Box>
                          <Box sx={{ backgroundColor: "teal10.main", borderRadius: "0px 4px 4px 0px", padding: "4px", width: "90px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography
                              fontSize={20}
                              fontWeight={500}
                              align="center"
                              sx={{ color: "swagalBlack.main" }}
                            >
                              {summaryShopPrice.accPrice.toSwagalAmount()}
                            </Typography>
                          </Box>
                        </>) : (<>
                          <Box sx={{ backgroundColor: "bgLight30.main", borderRadius: "4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img
                              src={shops[currentShop].logo as string}
                              style={{
                                maxHeight: "40px",
                                maxWidth: "40px",
                                margin: "auto",
                              }}
                              alt="swagal"
                              loading="lazy"
                            />
                          </Box>
                          <Box sx={{ backgroundColor: "invis.main", borderRadius: "4px", padding: "4px", width: "90px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography
                              fontSize={20}
                              fontWeight={400}
                              align="center"
                              sx={{ color: "swagalBlack.main" }}
                            >
                              {summaryShopPrice.accPrice.toSwagalAmount()}
                            </Typography>
                          </Box>
                        </>)
                      }
                    </Box>
                  </Grid>
                );
              })
              }
            </Grid>
          </StyledBox>
          <StyledBox sx={{
            backgroundColor: 'bgTeal.main',
          }}>
            <Box
              sx={{
                paddingLeft: "24px",
                paddingRight: "24px",
                paddingTop: "12px",
                paddingBottom: "180px",
                display: "flex",
                flexDirextion: "row",
                flexFlow: "space-between"
              }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                lineHeight={"24px"}
                sx={{ color: "swagalBlack.main", flexGrow: 1 }}
              >
                Diff € / %
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                lineHeight={"24px"}
                align="right"
                sx={{ color: "swagalBlack.main", flexGrow: 1 }}
              >
                {cartSummaryStats.absDiff.toSwagalAmount()} / {cartSummaryStats.relDiff}%
              </Typography>
            </Box>
          </StyledBox>

        </SwipeableDrawer>
      </Hidden>
    </Root>
  );
}
