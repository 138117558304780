import { Alert, Hidden, Snackbar } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../features/notification/notificationSlice";

export default function Notification() {
  const { openSnackBar } = useSelector((state: any) => state.notification);
  const dispatch = useDispatch();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeNotification())
  };
  return (
    <>
      <Hidden lgDown>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert icon={<CheckCircleOutlineIcon style={{ fill: "white" }}></CheckCircleOutlineIcon>}
            onClose={handleClose} sx={{ width: '100%', backgroundColor: "swagalTeal.main", color: "swagalWhite.main" }}>
            Vielen Dank für dein Feedback :D!
          </Alert>
        </Snackbar>
      </Hidden>
      <Hidden lgUp>
        <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert icon={<CheckCircleOutlineIcon style={{ fill: "white" }}></CheckCircleOutlineIcon>}
            onClose={handleClose} sx={{ width: '100%', backgroundColor: "swagalTeal.main", color: "swagalWhite.main" }}>
            Vielen Dank für dein Feedback :D!
          </Alert>
        </Snackbar>
      </Hidden>
    </>)
}