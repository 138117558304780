import {
  CartCategoryDto,
  CartItemComparisonDto,
  ProductDtoUnitEnum,
} from "../api";
import { CartSummary } from "../domain/types";

export const buildCartSummaryForEnabledProductsInCategory = (
  shoppingCartItemComparisons: CartItemComparisonDto[]
): CartSummary => {
  //console.log(shoppingCartItemComparisons)
  const dummyForProducts = {
    prices: [
      {
        shop: "BILLA",
        price: 7.26,
      },
      {
        shop: "HOFER",
        price: 3.26,
      },
      {
        shop: "INTERSPAR",
        price: 6.26,
      },
      {
        shop: "GURKERL",
        price: 9.26,
      },
    ],
  };

  return dummyForProducts;
};

export const buildCartSummaryForEnabledCategories = (
  shoppingCartCategories: CartCategoryDto[]
): CartSummary => {
  const dummyForCategories = {
    prices: [
      {
        shop: "BILLA",
        price: 28.26,
      },
      {
        shop: "HOFER",
        price: 29.26,
      },
      {
        shop: "INTERSPAR",
        price: 32.26,
      },
      {
        shop: "GURKERL",
        price: 45.26,
      },
    ],
  };

  return dummyForCategories;
};

export interface SwagalAmountFormatOptions {
  includeCurrency: boolean,
  spaceBetween?: boolean
}

declare global {
  export interface Number {
    toSwagalAmount(format?: SwagalAmountFormatOptions): string;
    toWeight(): String;
  }
}

Number.prototype.toSwagalAmount = function (this: number, format: SwagalAmountFormatOptions = { includeCurrency: true, spaceBetween: true }) {
  const rounded = Math.round(this);
  const euro = Math.floor(rounded / 100);
  const cent = rounded % 100; // .toFixed(0) does round cent value
  const centStr = (cent < 10) ? `0${cent.toFixed(0)}` : cent.toFixed(0);
  const spaceBetween = format.spaceBetween ?? true;
  const space = spaceBetween ? ' ' : '';
  const currency = format.includeCurrency ? `${space}€` : '';
  return `${euro.toFixed(0)},${centStr}${currency}`;
};

Number.prototype.toWeight = function (this: number) {
  const hasDecimals = (this - Math.floor(this)) !== 0; 
  if (hasDecimals) {
    return this.toFixed(2).toString().replace(".", ",")
  } else {
    return Math.floor(this).toString()
  }
};

export const getUnitText = (unit: ProductDtoUnitEnum): string => {
  switch (unit) {
    case ProductDtoUnitEnum.None: return ''
    case ProductDtoUnitEnum.Stk: return 'Stk'
    case ProductDtoUnitEnum.L: return 'l'
    case ProductDtoUnitEnum.Kg: return 'kg'
    case ProductDtoUnitEnum.Meter: return 'm'
    default: return ''
  }
};