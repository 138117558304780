/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CartCategoryDto
 */
export interface CartCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CartCategoryDto
     */
    'name': CartCategoryDtoNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CartCategoryDto
     */
    'enabled': boolean;
    /**
     * 
     * @type {Array<CartItemComparisonDto>}
     * @memberof CartCategoryDto
     */
    'cartItemComparisons': Array<CartItemComparisonDto>;
}

export const CartCategoryDtoNameEnum = {
    Grundnahrungsmittel: 'GRUNDNAHRUNGSMITTEL',
    BrotBackwaren: 'BROT_BACKWAREN',
    Kuehlwaren: 'KUEHLWAREN',
    SuessesSalziges: 'SUESSES_SALZIGES',
    Getraenke: 'GETRAENKE',
    Tiefkuehlwaren: 'TIEFKUEHLWAREN',
    HaushaltDrogerie: 'HAUSHALT_DROGERIE',
    Tierbedarf: 'TIERBEDARF',
    ObstGemuese: 'OBST_GEMUESE',
    FleischFisch: 'FLEISCH_FISCH',
    Sonstiges: 'SONSTIGES'
} as const;

export type CartCategoryDtoNameEnum = typeof CartCategoryDtoNameEnum[keyof typeof CartCategoryDtoNameEnum];

/**
 * 
 * @export
 * @interface CartDto
 */
export interface CartDto {
    /**
     * 
     * @type {string}
     * @memberof CartDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CartDto
     */
    'shopsSelected': Array<CartDtoShopsSelectedEnum>;
    /**
     * 
     * @type {string}
     * @memberof CartDto
     */
    'preference': CartDtoPreferenceEnum;
    /**
     * 
     * @type {Array<CartCategoryDto>}
     * @memberof CartDto
     */
    'categories': Array<CartCategoryDto>;
}

export const CartDtoShopsSelectedEnum = {
    Gurkerl: 'GURKERL',
    Interspar: 'INTERSPAR',
    Hofer: 'HOFER',
    Billa: 'BILLA'
} as const;

export type CartDtoShopsSelectedEnum = typeof CartDtoShopsSelectedEnum[keyof typeof CartDtoShopsSelectedEnum];
export const CartDtoPreferenceEnum = {
    Price: 'PRICE',
    Region: 'REGION',
    Bio: 'BIO'
} as const;

export type CartDtoPreferenceEnum = typeof CartDtoPreferenceEnum[keyof typeof CartDtoPreferenceEnum];

/**
 * 
 * @export
 * @interface CartItemComparisonDto
 */
export interface CartItemComparisonDto {
    /**
     * 
     * @type {string}
     * @memberof CartItemComparisonDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CartItemComparisonDto
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CartItemComparisonDto
     */
    'description': string;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof CartItemComparisonDto
     */
    'products': Array<ProductDto>;
}
/**
 * 
 * @export
 * @interface CreateFeedbackDto
 */
export interface CreateFeedbackDto {
    /**
     * 
     * @type {number}
     * @memberof CreateFeedbackDto
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedbackDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface DataPoints
 */
export interface DataPoints {
    /**
     * 
     * @type {Array<string>}
     * @memberof DataPoints
     */
    'timestamps': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DataPoints
     */
    'prices': Array<number>;
}
/**
 * 
 * @export
 * @interface FeedbackDto
 */
export interface FeedbackDto {
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeedbackDto
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface PageProductDto
 */
export interface PageProductDto {
    /**
     * 
     * @type {number}
     * @memberof PageProductDto
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductDto
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductDto
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof PageProductDto
     */
    'content'?: Array<ProductDto>;
    /**
     * 
     * @type {number}
     * @memberof PageProductDto
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageProductDto
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageProductDto
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageProductDto
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductDto
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductDto
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductDto
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface ProductDetailDto
 */
export interface ProductDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'shop': ProductDetailDtoShopEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'shopCategory': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'shopSubCategory': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'swagalCategory': ProductDetailDtoSwagalCategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDto
     */
    'currentPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'unit': ProductDetailDtoUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailDto
     */
    'normalizedPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'salesBadge'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'currentPriceClassification': ProductDetailDtoCurrentPriceClassificationEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailDto
     */
    'productUrl'?: string;
    /**
     * 
     * @type {DataPoints}
     * @memberof ProductDetailDto
     */
    'dataPoints': DataPoints;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailDto
     */
    'isBio': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailDto
     */
    'isAut': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailDto
     */
    'isVegetarian': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailDto
     */
    'isVegan': boolean;
}

export const ProductDetailDtoShopEnum = {
    Gurkerl: 'GURKERL',
    Interspar: 'INTERSPAR',
    Hofer: 'HOFER',
    Billa: 'BILLA'
} as const;

export type ProductDetailDtoShopEnum = typeof ProductDetailDtoShopEnum[keyof typeof ProductDetailDtoShopEnum];
export const ProductDetailDtoSwagalCategoryEnum = {
    Grundnahrungsmittel: 'GRUNDNAHRUNGSMITTEL',
    BrotBackwaren: 'BROT_BACKWAREN',
    Kuehlwaren: 'KUEHLWAREN',
    SuessesSalziges: 'SUESSES_SALZIGES',
    Getraenke: 'GETRAENKE',
    Tiefkuehlwaren: 'TIEFKUEHLWAREN',
    HaushaltDrogerie: 'HAUSHALT_DROGERIE',
    Tierbedarf: 'TIERBEDARF',
    ObstGemuese: 'OBST_GEMUESE',
    FleischFisch: 'FLEISCH_FISCH',
    Sonstiges: 'SONSTIGES'
} as const;

export type ProductDetailDtoSwagalCategoryEnum = typeof ProductDetailDtoSwagalCategoryEnum[keyof typeof ProductDetailDtoSwagalCategoryEnum];
export const ProductDetailDtoUnitEnum = {
    None: 'NONE',
    Kg: 'KG',
    L: 'L',
    Meter: 'METER',
    Stk: 'STK'
} as const;

export type ProductDetailDtoUnitEnum = typeof ProductDetailDtoUnitEnum[keyof typeof ProductDetailDtoUnitEnum];
export const ProductDetailDtoCurrentPriceClassificationEnum = {
    Cheap: 'CHEAP',
    Normal: 'NORMAL',
    Expensive: 'EXPENSIVE'
} as const;

export type ProductDetailDtoCurrentPriceClassificationEnum = typeof ProductDetailDtoCurrentPriceClassificationEnum[keyof typeof ProductDetailDtoCurrentPriceClassificationEnum];

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'shop': ProductDtoShopEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'brand'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'shopCategory': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'shopSubCategory': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'swagalCategory': ProductDtoSwagalCategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'currentPrice': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'unit': ProductDtoUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'normalizedPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'salesBadge'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'currentPriceClassification': ProductDtoCurrentPriceClassificationEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'productUrl'?: string;
}

export const ProductDtoShopEnum = {
    Gurkerl: 'GURKERL',
    Interspar: 'INTERSPAR',
    Hofer: 'HOFER',
    Billa: 'BILLA'
} as const;

export type ProductDtoShopEnum = typeof ProductDtoShopEnum[keyof typeof ProductDtoShopEnum];
export const ProductDtoSwagalCategoryEnum = {
    Grundnahrungsmittel: 'GRUNDNAHRUNGSMITTEL',
    BrotBackwaren: 'BROT_BACKWAREN',
    Kuehlwaren: 'KUEHLWAREN',
    SuessesSalziges: 'SUESSES_SALZIGES',
    Getraenke: 'GETRAENKE',
    Tiefkuehlwaren: 'TIEFKUEHLWAREN',
    HaushaltDrogerie: 'HAUSHALT_DROGERIE',
    Tierbedarf: 'TIERBEDARF',
    ObstGemuese: 'OBST_GEMUESE',
    FleischFisch: 'FLEISCH_FISCH',
    Sonstiges: 'SONSTIGES'
} as const;

export type ProductDtoSwagalCategoryEnum = typeof ProductDtoSwagalCategoryEnum[keyof typeof ProductDtoSwagalCategoryEnum];
export const ProductDtoUnitEnum = {
    None: 'NONE',
    Kg: 'KG',
    L: 'L',
    Meter: 'METER',
    Stk: 'STK'
} as const;

export type ProductDtoUnitEnum = typeof ProductDtoUnitEnum[keyof typeof ProductDtoUnitEnum];
export const ProductDtoCurrentPriceClassificationEnum = {
    Cheap: 'CHEAP',
    Normal: 'NORMAL',
    Expensive: 'EXPENSIVE'
} as const;

export type ProductDtoCurrentPriceClassificationEnum = typeof ProductDtoCurrentPriceClassificationEnum[keyof typeof ProductDtoCurrentPriceClassificationEnum];

/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
}

/**
 * FeedbackControllerApi - axios parameter creator
 * @export
 */
export const FeedbackControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Feedback
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedback: async (createFeedbackDto: CreateFeedbackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFeedbackDto' is not null or undefined
            assertParamExists('createFeedback', 'createFeedbackDto', createFeedbackDto)
            const localVarPath = `/api/feedbacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFeedbackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackControllerApi - functional programming interface
 * @export
 */
export const FeedbackControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Feedback
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeedback(createFeedbackDto: CreateFeedbackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeedback(createFeedbackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackControllerApi - factory interface
 * @export
 */
export const FeedbackControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Feedback
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedback(createFeedbackDto: CreateFeedbackDto, options?: any): AxiosPromise<FeedbackDto> {
            return localVarFp.createFeedback(createFeedbackDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackControllerApi - object-oriented interface
 * @export
 * @class FeedbackControllerApi
 * @extends {BaseAPI}
 */
export class FeedbackControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create Feedback
     * @param {CreateFeedbackDto} createFeedbackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackControllerApi
     */
    public createFeedback(createFeedbackDto: CreateFeedbackDto, options?: AxiosRequestConfig) {
        return FeedbackControllerApiFp(this.configuration).createFeedback(createFeedbackDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductControllerApi - axios parameter creator
 * @export
 */
export const ProductControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Products
         * @param {'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'} shop 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (shop: 'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA', productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shop' is not null or undefined
            assertParamExists('getProduct', 'shop', shop)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProduct', 'productId', productId)
            const localVarPath = `/api/products/{shop}/{productId}`
                .replace(`{${"shop"}}`, encodeURIComponent(String(shop)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Products
         * @param {string} search 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>} [shops] 
         * @param {'GRUNDNAHRUNGSMITTEL' | 'BROT_BACKWAREN' | 'KUEHLWAREN' | 'SUESSES_SALZIGES' | 'GETRAENKE' | 'TIEFKUEHLWAREN' | 'HAUSHALT_DROGERIE' | 'TIERBEDARF' | 'OBST_GEMUESE' | 'FLEISCH_FISCH' | 'SONSTIGES'} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (search: string, page: number, pageSize: number, shops?: Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>, category?: 'GRUNDNAHRUNGSMITTEL' | 'BROT_BACKWAREN' | 'KUEHLWAREN' | 'SUESSES_SALZIGES' | 'GETRAENKE' | 'TIEFKUEHLWAREN' | 'HAUSHALT_DROGERIE' | 'TIERBEDARF' | 'OBST_GEMUESE' | 'FLEISCH_FISCH' | 'SONSTIGES', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('getProducts', 'search', search)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getProducts', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getProducts', 'pageSize', pageSize)
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (shops) {
                localVarQueryParameter['shops'] = shops;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductControllerApi - functional programming interface
 * @export
 */
export const ProductControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Products
         * @param {'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'} shop 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(shop: 'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA', productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(shop, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Products
         * @param {string} search 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>} [shops] 
         * @param {'GRUNDNAHRUNGSMITTEL' | 'BROT_BACKWAREN' | 'KUEHLWAREN' | 'SUESSES_SALZIGES' | 'GETRAENKE' | 'TIEFKUEHLWAREN' | 'HAUSHALT_DROGERIE' | 'TIERBEDARF' | 'OBST_GEMUESE' | 'FLEISCH_FISCH' | 'SONSTIGES'} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(search: string, page: number, pageSize: number, shops?: Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>, category?: 'GRUNDNAHRUNGSMITTEL' | 'BROT_BACKWAREN' | 'KUEHLWAREN' | 'SUESSES_SALZIGES' | 'GETRAENKE' | 'TIEFKUEHLWAREN' | 'HAUSHALT_DROGERIE' | 'TIERBEDARF' | 'OBST_GEMUESE' | 'FLEISCH_FISCH' | 'SONSTIGES', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProductDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(search, page, pageSize, shops, category, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductControllerApi - factory interface
 * @export
 */
export const ProductControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Products
         * @param {'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'} shop 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(shop: 'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA', productId: string, options?: any): AxiosPromise<ProductDetailDto> {
            return localVarFp.getProduct(shop, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Products
         * @param {string} search 
         * @param {number} page 
         * @param {number} pageSize 
         * @param {Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>} [shops] 
         * @param {'GRUNDNAHRUNGSMITTEL' | 'BROT_BACKWAREN' | 'KUEHLWAREN' | 'SUESSES_SALZIGES' | 'GETRAENKE' | 'TIEFKUEHLWAREN' | 'HAUSHALT_DROGERIE' | 'TIERBEDARF' | 'OBST_GEMUESE' | 'FLEISCH_FISCH' | 'SONSTIGES'} [category] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(search: string, page: number, pageSize: number, shops?: Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>, category?: 'GRUNDNAHRUNGSMITTEL' | 'BROT_BACKWAREN' | 'KUEHLWAREN' | 'SUESSES_SALZIGES' | 'GETRAENKE' | 'TIEFKUEHLWAREN' | 'HAUSHALT_DROGERIE' | 'TIERBEDARF' | 'OBST_GEMUESE' | 'FLEISCH_FISCH' | 'SONSTIGES', options?: any): AxiosPromise<PageProductDto> {
            return localVarFp.getProducts(search, page, pageSize, shops, category, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductControllerApi - object-oriented interface
 * @export
 * @class ProductControllerApi
 * @extends {BaseAPI}
 */
export class ProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get Products
     * @param {'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'} shop 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getProduct(shop: 'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA', productId: string, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getProduct(shop, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Products
     * @param {string} search 
     * @param {number} page 
     * @param {number} pageSize 
     * @param {Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>} [shops] 
     * @param {'GRUNDNAHRUNGSMITTEL' | 'BROT_BACKWAREN' | 'KUEHLWAREN' | 'SUESSES_SALZIGES' | 'GETRAENKE' | 'TIEFKUEHLWAREN' | 'HAUSHALT_DROGERIE' | 'TIERBEDARF' | 'OBST_GEMUESE' | 'FLEISCH_FISCH' | 'SONSTIGES'} [category] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getProducts(search: string, page: number, pageSize: number, shops?: Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>, category?: 'GRUNDNAHRUNGSMITTEL' | 'BROT_BACKWAREN' | 'KUEHLWAREN' | 'SUESSES_SALZIGES' | 'GETRAENKE' | 'TIEFKUEHLWAREN' | 'HAUSHALT_DROGERIE' | 'TIERBEDARF' | 'OBST_GEMUESE' | 'FLEISCH_FISCH' | 'SONSTIGES', options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getProducts(search, page, pageSize, shops, category, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SwagalControllerApi - axios parameter creator
 * @export
 */
export const SwagalControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Swagal
         * @param {Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>} shops 
         * @param {'PRICE' | 'REGION' | 'BIO'} preference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwagal: async (shops: Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>, preference: 'PRICE' | 'REGION' | 'BIO', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shops' is not null or undefined
            assertParamExists('getSwagal', 'shops', shops)
            // verify required parameter 'preference' is not null or undefined
            assertParamExists('getSwagal', 'preference', preference)
            const localVarPath = `/api/swagal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shops) {
                localVarQueryParameter['shops'] = shops;
            }

            if (preference !== undefined) {
                localVarQueryParameter['preference'] = preference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Scrape Web Shop
         * @param {'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'} shop 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scrape: async (shop: 'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shop' is not null or undefined
            assertParamExists('scrape', 'shop', shop)
            const localVarPath = `/api/swagal/scrape`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shop !== undefined) {
                localVarQueryParameter['shop'] = shop;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SwagalControllerApi - functional programming interface
 * @export
 */
export const SwagalControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SwagalControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Swagal
         * @param {Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>} shops 
         * @param {'PRICE' | 'REGION' | 'BIO'} preference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSwagal(shops: Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>, preference: 'PRICE' | 'REGION' | 'BIO', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSwagal(shops, preference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Scrape Web Shop
         * @param {'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'} shop 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scrape(shop: 'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scrape(shop, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SwagalControllerApi - factory interface
 * @export
 */
export const SwagalControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SwagalControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Swagal
         * @param {Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>} shops 
         * @param {'PRICE' | 'REGION' | 'BIO'} preference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwagal(shops: Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>, preference: 'PRICE' | 'REGION' | 'BIO', options?: any): AxiosPromise<CartDto> {
            return localVarFp.getSwagal(shops, preference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Scrape Web Shop
         * @param {'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'} shop 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scrape(shop: 'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA', options?: any): AxiosPromise<string> {
            return localVarFp.scrape(shop, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SwagalControllerApi - object-oriented interface
 * @export
 * @class SwagalControllerApi
 * @extends {BaseAPI}
 */
export class SwagalControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get Swagal
     * @param {Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>} shops 
     * @param {'PRICE' | 'REGION' | 'BIO'} preference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwagalControllerApi
     */
    public getSwagal(shops: Array<'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'>, preference: 'PRICE' | 'REGION' | 'BIO', options?: AxiosRequestConfig) {
        return SwagalControllerApiFp(this.configuration).getSwagal(shops, preference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Scrape Web Shop
     * @param {'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA'} shop 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SwagalControllerApi
     */
    public scrape(shop: 'GURKERL' | 'INTERSPAR' | 'HOFER' | 'BILLA', options?: AxiosRequestConfig) {
        return SwagalControllerApiFp(this.configuration).scrape(shop, options).then((request) => request(this.axios, this.basePath));
    }
}


