import { Box, Button, Grid, Hidden, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { ProductCard } from "./ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../async/actions";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProductDto } from "../../api";
import { resetSearch } from "../../features/search/searchSlice";
import Loading from "../../media/swagal-loading-v2.svg";

export default function ProductSearch() {
  return (
    <>
      <Hidden mdDown>
        <ProductSearchDesktop></ProductSearchDesktop>
      </Hidden>
      <Hidden mdUp>
        <ProductSearchMobile></ProductSearchMobile>
      </Hidden>
    </>
  );
}

const ProductSearchDesktop = () => {
  const { open } = useSelector((state: any) => state.disclaimer);
  const marginTop = open != "false" ? "177px" : "128px"
  return (
    <Box sx={{ marginTop: marginTop }}>
      <Box sx={{
        paddingTop: "32px", paddingBottom: "16px", display: "flex", direction: "column", justifyContent: "center",
        alignItems: "center", width: "100%"
      }}>
        <Box>
          <ProductSearchResults></ProductSearchResults>
        </Box>

        {/* insert product detail view */}
        <Outlet></Outlet>
      </Box>
    </Box>
  )
}

const ProductSearchMobile = () => {
  return (
    <Box sx={{ marginTop: 0 }}>
      <Box sx={{
        paddingTop: "32px", paddingBottom: "16px", display: "flex", direction: "column", justifyContent: "center",
        paddingLeft: "2px", paddingRight: "2px"
      }}>
        <ProductSearchResults></ProductSearchResults>
        {/* insert product detail view */}
        <Outlet></Outlet>
      </Box>
    </Box>
  )
}

const ProductSearchResults = () => {
  const { products, currentPage, totalPages, status } = useSelector((state: any) => state.search);
  const dispatch = useDispatch();
  const fetchMoreData = () => {
    dispatch(fetchProducts())
  }

  if (products.length === 0 && status != "loading") {
    return (<NoProductsFound></NoProductsFound>)
  }

  if (products.length === 0 && status == "loading") {
    return (<Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center", width: "100%"
    }}><img
        src={Loading}
        alt="swagal"
        loading="lazy"
        style={{ width: "50px", height: "50px" }}
      /></Box>)
  }

  return (
    <InfiniteScroll
      dataLength={products.length}
      next={fetchMoreData}
      hasMore={(currentPage + 1) < totalPages}
      loader={<Box sx={{
        display: "flex",
        marginTop: "24px",
        marginBottom: "24px",
        justifyContent: "center",
        alignItems: "center", width: "100%"
      }}><img
          src={Loading}
          alt="swagal"
          loading="lazy"
          style={{ width: "50px", height: "50px" }}
        /></Box>}
      scrollThreshold={0.9}
      style={{ overflow: "hidden" }}
      endMessage={<EndOfScroll></EndOfScroll>}
    >
      <Grid container columnSpacing={{ md: 12, sm: 12, xs: 2 }} rowSpacing={8} columns={12}
        columnGap={1}
        direction="row"
        justifyContent="center"
        padding="8px"
        alignItems="center">
        {
          products.map((product: ProductDto) => (
            <Grid key={product.shop + product.id} item padding={0}>
              <ProductCard product={product}></ProductCard>
            </Grid>
          ))
        }
      </Grid>
    </InfiniteScroll>
  )
}

const NoProductsFound = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBackToOverView = () => {
    dispatch(resetSearch())
    navigate("/")
  }
  return (
    <Box>
      <Typography fontWeight={600} fontSize={20} lineHeight="24px" padding="16px">Es wurden leider keine Ergebnisse für Ihre Suchanfrage gefunden.</Typography>
      <Typography fontWeight={400} fontSize={16} lineHeight="24px" paddingLeft="16px">Versuchen Sie es doch einmal mit einem anderen Suchbegriff oder einer anderen Filteroption.</Typography>
      <Button onClick={handleBackToOverView} sx={{ marginLeft: "16px", backgroundColor: "bgDefault.main", color: "swagalBlack.main", fontSize: "12px", fontWeight: "bold", marginTop: "48px", whiteSpace: "nowrap" }}>Zurück zur Übersicht</Button>
    </Box>
  )
}

const EndOfScroll = () => {
  const { products } = useSelector((state: any) => state.search);
  if (products.length === 0) {
    return (<></>)
  }
  return (
    <p style={{ textAlign: 'center', marginTop: "40px" }}>
      <b>🏁 Yay! You have seen it all. 🏁</b>
    </p>
  )
}