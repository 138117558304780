import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import {
  Box,
  DialogActions,
  Divider,
  FormControl,
  Hidden,
  Rating,
  TextField,
  styled,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import LogoText from "../media/Logo_3.svg";
import { useDispatch, useSelector } from "react-redux";
import { Feedback } from "../domain/types";
import { resetFeedback, updateMessage, updateStars } from "../features/feedback/feedbackSlice";
import { postFeedback } from "../async/actions";
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { openNotification } from "../features/notification/notificationSlice";

const emails = ["username@gmail.com", "user02@gmail.com"];

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function FeedbackDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;
  const feedback: Feedback = useSelector((state: any) => state.feedback);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetFeedback())
    onClose(selectedValue);
  };

  const handleRatingChange = (event: any) => {
    dispatch(updateStars(event.target.value));
  };

  const handleMessageChange = (event: any) => {
    dispatch(updateMessage(event.target.value));
  };

  const handleSendFeedback = () => {
    dispatch(postFeedback())
    dispatch(openNotification())
    onClose(selectedValue);
  }

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle sx={{
          display: "flex", justifyContent: "center",
          alignItems: "center"
        }}>Bewertung</DialogTitle>
        <FormControl>
          <Box sx={{ paddingLeft: "24px", paddingRight: "24px" }}>
            <Box sx={{ paddingBottom: "24px" }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '8px'
              }}>
                <Typography lineHeight="24px" fontSize="20px" color="swagalBlack.main">
                  Wie würdest du
                </Typography>
                <img src={LogoText} height="22px" style={{ marginBottom: '-4px', marginRight: '6px', marginLeft: '6px' }} />
                <Typography lineHeight="24px" fontSize="20px" color="swagalBlack.main">
                  bewerten?
                </Typography>
              </div>
              <Typography
                marginTop="8px"
                fontSize={16}
                color="textSecondary.main"
              >
                Hilf uns die Platform zu verbessern!
              </Typography>
              <Rating
                name="size-large"
                defaultValue={feedback.stars}
                onChange={handleRatingChange}
                size="large"
                sx={{
                  paddingTop: "16px",
                  '& .MuiRating-iconFilled': {
                    color: 'swagalTeal.main', // Change this to the desired color
                    fontSize: "40px"
                  },
                  '& .MuiRating-iconEmpty': {
                    color: 'gray', // Change this to the desired color
                    fontSize: "40px"
                  },
                }}
              />
            </Box>
            <Divider></Divider>

            <Box sx={{ paddingTop: "24px", paddingBottom: "24px" }}>
              <Typography lineHeight="24px" marginTop="8px" fontSize="20px" color="swagalBlack.main">
                Möchtest du uns sonst noch was mitteilen?
              </Typography>
              <Typography
                marginTop="8px"
                fontSize={16}
                color="textSecondary.main"
              >
                Wir sind stets bemüht, eure Ideen umzusetzen!
              </Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={4}
                sx={{ width: "100%", marginTop: "16px", borderRadius: "8px" }}
                onChange={handleMessageChange}
                defaultValue={feedback.message}
              />
            </Box>
          </Box>

          <Divider></Divider>

          <DialogActions
            sx={{
              paddingTop: "16px",
              paddingBottom: "16px",
              paddingRight: "24px",
              margin: "0px",
            }}
          >
            <Button
              sx={{
                color: "swagalWhite.main",
                height: "40px",
                fontWeight: "600",
                marginLeft: "4px"
              }}
              onClick={handleSendFeedback}
              color="swagalTeal"
              variant="contained"
              disableElevation
            >
              <Typography fontWeight="bold" fontSize={14}>
                BEWERTUNG SENDEN
              </Typography>
            </Button>
          </DialogActions>
        </FormControl>
      </Dialog>
    </>
  );
}

const Root = styled('div')(({ theme }) => ({
  height: '100%'
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  position: 'absolute',
  top: 8,
  borderRadius: "6px",
  left: 'calc(50% - 15px)',
}));

function FeedbackDialogMobileNew(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;
  const feedback: Feedback = useSelector((state: any) => state.feedback);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetFeedback())
    onClose(selectedValue);
  };

  const handleRatingChange = (event: any) => {
    dispatch(updateStars(event.target.value));
  };

  const handleMessageChange = (event: any) => {
    dispatch(updateMessage(event.target.value));
  };

  const handleSendFeedback = () => {
    dispatch(postFeedback())
    dispatch(openNotification())
    onClose(selectedValue);
  }
  return (
    <>
      <Root>
        <CssBaseline />
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(100% - ${40}px)`,
              overflow: 'visible',
              borderRadius: "24px"
            },
          }}
        />
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          onOpen={() => console.log("ztest")}
          swipeAreaWidth={0}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{ zIndex: 1350 }}
        >
          <StyledBox
            sx={{
              position: 'absolute',
              top: 0,
              paddingTop: "24px",
              right: 0,
              left: 0,
              zIndex: 'inherit'
            }}
          >
            <Puller />
            <Box sx={{
              paddingTop: "16px",
              paddingBottom: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "1px solid black",
              borderColor: "bgDefault.main"
            }}>
              <Typography fontWeight={600} fontSize={16} color="black">Bewertung</Typography>
            </Box>
          </StyledBox>
          <StyledBox
            sx={{
              px: 2,
              pb: 2,
              marginTop: "32px",
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Box sx={{ marginTop: "80px", marginBottom: "48px", paddingLeft: "32px", paddingRight: "32px" }}>
              <Box sx={{ paddingBottom: "24px" }}>
                <Box flexWrap="wrap" display="flex" flexDirection="row" alignItems="center">
                  <Typography lineHeight="24px" fontSize="20px" fontWeight={600} color="swagalBlack.main">
                    Wie würdest du
                  </Typography>
                  <img src={LogoText} height="22px" style={{ marginBottom: '-4px', marginRight: '6px', marginLeft: '6px' }} />
                  <Typography lineHeight="24px" fontSize="20px" fontWeight={600} color="swagalBlack.main">
                    bewerten?
                  </Typography>
                </Box>

                <Typography
                  marginTop="8px"
                  fontSize={16}
                  color="textSecondary.main"
                >
                  Hilf uns die Platform zu verbessern!
                </Typography>
                <Rating
                  name="size-large"
                  defaultValue={feedback.stars}
                  onChange={handleRatingChange}
                  size="large"
                  sx={{
                    paddingTop: "16px",
                    '& .MuiRating-iconFilled': {
                      color: 'swagalTeal.main', // Change this to the desired color
                      fontSize: "40px"
                    },
                    '& .MuiRating-iconEmpty': {
                      color: 'gray', // Change this to the desired color
                      fontSize: "40px"
                    },
                  }}
                />
              </Box>

              <Divider></Divider>

              <Box sx={{ paddingTop: "24px", paddingBottom: "24px" }}>
                <Typography marginTop="8px" fontSize={16} fontWeight={600} color="swagalBlack.main">
                  Möchtest du uns sonst noch was mitteilen?
                </Typography>
                <Typography
                  marginTop="8px"
                  fontSize={16}
                  color="textSecondary.main"
                >
                  Wir sind stets bemüht, eure Ideen umzusetzen!
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  sx={{ width: "100%", marginTop: "16px", borderRadius: "8px", borderWidth: "0.25px", borderColor: "textSecondary.main" }}
                  onChange={handleMessageChange}
                  defaultValue={feedback.message}
                />
              </Box>
            </Box>
          </StyledBox>
          <Box sx={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            backgroundColor: "swagalWhite.main",
            paddingBottom: "16px",
            boxShadow: "0px -1px 10px 0px #0000001A"
          }}>
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "12px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingBottom: "12px"
            }}>
              <Button
                color="swagalWhite"
                onClick={handleSendFeedback}
                sx={{ backgroundColor: "swagalTeal.main", width: "100%" }}
              >
                <Typography fontWeight="bold" fontSize={14}>
                  BEWERTUNG SENDEN
                </Typography>
              </Button>
            </Box>
          </Box>
        </SwipeableDrawer>
      </Root></>
  );
}

export default function FeedbackDialogEntrypoint() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <Hidden mdDown>
        <Button
          variant="contained"
          startIcon={<StarIcon sx={{ color: "swagalWhite.main" }} />}
          color="swagalBlack"
          onClick={handleClickOpen}
          disableElevation
        >
          <Typography color="swagalWhite.main" align="center">SWAGAL BEWERTEN</Typography>
        </Button>
        <FeedbackDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      </Hidden>
      <Hidden mdUp>
        <Box width="100%">
          <Button
            variant="contained"
            sx={{ width: "100%" }}
            startIcon={<StarIcon sx={{ color: "swagalWhite.main" }} />}
            color="swagalBlack"
            onClick={handleClickOpen}
            disableElevation
          >
            <Typography color="swagalWhite.main">SWAGAL BEWERTEN</Typography>
          </Button>
          <FeedbackDialogMobileNew
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
          />
        </Box>
      </Hidden>
    </>
  );
}
