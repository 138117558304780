import './App.css';
import Header from './components/Header';
import { Outlet, Route, Routes } from 'react-router-dom';
import ProductSearch from './components/search/ProductSearch';
import Cart from './components/cart/Cart';
import CartCategories from './components/cart/CartCategories';
import CartCategoryProducts from './components/cart/CartCategoryProducts';
import CategorySelection from './components/CategorySelection';
import { Box, Hidden } from '@mui/material';
import NotFound from './components/NotFound';
import { ProductDetailsMobileDirectly, ProductDetailsView } from './components/ProductDrawer';
import About from './components/About';
import Notification from './components/Notification';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { IntroDesktop, IntroMobile } from './components/Intro';
import { useSelector } from 'react-redux';

function App() {
  const { open } = useSelector((state: any) => state.intro);
  return (
    <>
      <IntroDesktop open={open}></IntroDesktop>
      <IntroMobile open={open}></IntroMobile>
      <Notification></Notification>
      <Hidden mdDown>
        <Header></Header>
        <Box sx={{ paddingTop: "81px" }}>
          <Routes>
            <Route path="/impressum" element={<About />}></Route>
            <Route path="/" element={<Cart />}>
              <Route path="/" element={<CartCategories />}></Route>
              <Route path=":categoryId" element={<CartCategoryProducts />}>
                {/*<Route path=":productId" element={<ProductDrawer />} />*/}
              </Route>
            </Route>
            <Route path="/products" element={<ProductSearch />}>
              <Route path="/products/:productId" element={<ProductDetailsView />} />
            </Route>
            <Route path="*" element={<NotFound></NotFound>}></Route>
          </Routes>
          <Outlet></Outlet>
        </Box>
      </Hidden>
      <Hidden mdUp>
        {open !== "false" ? (<></>) : (
          <>
            <Header></Header>
            <Box sx={{ paddingTop: "73px" }}>
              <CategorySelection></CategorySelection>
              <Routes>
                <Route path="/impressum" element={<About />}></Route>
                <Route path="/" element={<Cart />}>
                  <Route path="/" element={<CartCategories />}></Route>
                  <Route path=":categoryId" element={<CartCategoryProducts />}>
                    {/*<Route path=":productId" element={<ProductDrawer />} />*/}
                  </Route>
                </Route>
                <Route path="/products" element={<ProductSearch />}>
                  <Route path=":productId" element={<ProductDetailsMobileDirectly />} />
                </Route>
              </Routes>
              <Outlet></Outlet>
            </Box></>)}
      </Hidden>
    </>

  );
}

export default App;
