import { createSlice } from '@reduxjs/toolkit';

const cartSummarySlice = createSlice({
  name: 'cartSummary',
  initialState: {
    sums: [
      {
        price: "6,26 €",
        cheapest: false
      },
      {
        price: "6,27 €",
        cheapest: true
      },
      {
        price: "6,28 €",
        cheapest: false
      },
      {
        price: "6,29 €",
        cheapest: false
      }
    ]
  },
  reducers: {
    updateSummaryWith(state, param) {
      state.sums = param.payload.prices
    }
  },
});

export const { updateSummaryWith } = cartSummarySlice.actions;

export default cartSummarySlice.reducer;