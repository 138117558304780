import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FeedbackControllerApi,
  ProductControllerApi,
  CartDtoShopsSelectedEnum,
  SwagalControllerApi,
  CartDtoPreferenceEnum,
  ProductDtoSwagalCategoryEnum,
} from "../api";
import { API_BASE_URL, API_CONFIG } from "../api-config";
import { FilterState } from "../features/filter/filterSlice";

export const fetchProducts: any = createAsyncThunk(
  "search/fetchProducts",
  async (_, { getState }) => {
    const state = getState();
    const currentPage = (state as any).search.currentPage;
    const filterString = (state as any).search.searchString;
    const shopFilter = (state as FilterState).filter.data.selectedShops;
    const categoryFilter = (state as { categoryselect: { selected: string } })
      .categoryselect.selected;

    const api = new ProductControllerApi(API_CONFIG, API_BASE_URL);
    const response = await api.getProducts(
      filterString,
      currentPage + 1,
      20,
      shopFilter,
      categoryFilter as ProductDtoSwagalCategoryEnum
    );
    return response.data;
  }
);

export const fetchCart: any = createAsyncThunk(
  "cartData/fetchData",
  async (_, { getState }) => {
    const state = getState();
    const shopFilter = (state as FilterState).filter.data.selectedShops;
    const preference = (state as FilterState).filter.data.preference;
    const api = new SwagalControllerApi(API_CONFIG, API_BASE_URL);
    const response = await api.getSwagal(
      shopFilter,
      preference as ('PRICE' | 'REGION' | 'BIO')
    );

    //console.log(response.data);
    return response.data;
  }
);

export const postFeedback: any = createAsyncThunk(
  "feedback/post",
  async (_, { getState }) => {
    const state = getState();
    const feedback = (state as { feedback: { stars: number, message: string } }).feedback;
    const api = new FeedbackControllerApi(API_CONFIG, API_BASE_URL);
    const createFeedbackDto = { rating: feedback.stars, message: feedback.message };
    const response = await api.createFeedback(createFeedbackDto);
    return response.data;
  }
);
