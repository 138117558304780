import { createSlice, current } from '@reduxjs/toolkit';
import { fetchProducts } from '../../async/actions';
import { ProductDto } from '../../api';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    products: [],
    searchString: "",
    preference: "PRICE",
    status: 'loading',
    currentPage: -1,
    pageSize: 20,
    totalPages: 0,
    totalElements: 0
  },
  reducers: {
    updateSearchString: (state, param) => {
      state.searchString = param.payload
      state.currentPage = -1
      state.products = []
      state.totalPages = 0
      state.totalElements = 0
      state.status = 'loading'
    },
    resetSearch: (state) => {
      state.searchString = ""
      state.currentPage = -1
      state.products = []
      state.totalPages = 0
      state.totalElements = 0
    },
    resetResults: (state) => {
      state.currentPage = -1
      state.products = []
      state.totalPages = 0
      state.totalElements = 0
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.products = state.products.concat(action.payload.content)
        state.currentPage = action.payload.number
        state.totalPages = action.payload.totalPages
        state.totalElements = action.payload.totalElements
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed'
      })
  }
});

export const { updateSearchString, resetSearch, resetResults } = searchSlice.actions;

export default searchSlice.reducer;
