import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Logo from "../media/Logo_3.svg";
import logoSmall from "../media/swagal-w-logo.svg";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase, styled } from "@mui/material";
import FeedbackDialogEntrypoint from "./Feedback";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetSearch, updateSearchString } from "../features/search/searchSlice";
import { fetchProducts } from "../async/actions";
import { useEffect } from "react";
import Hidden from '@mui/material/Hidden';
import FilterDialogEntrypoint from "./Filter";
import CategorySelection from "./CategorySelection";
import { Disclaimer } from "./Disclaimer";
import { init } from "../features/categoryselect/categoryselectSlice";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    width: "400px",
    paddingLeft: "16px",
    fontSize: "16px",
  },
}));

const StyledInputBaseMobile = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "bgDefault.main"
  },
}));

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const debounceDelay = 300;
  const { searchString } = useSelector((state: any) => state.search);
  const { open } = useSelector((state: any) => state.disclaimer);

  const handleSearchChange = (event: any) => {
    dispatch(updateSearchString(event.target.value));
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      dispatch(fetchProducts());
      // make sure that /products is current path
      if (!(location.pathname as string).includes("products") && searchString.length != 0) {
        navigate("/products")
      }
    }, debounceDelay);

    return () => { clearTimeout(debounceTimer) };
  }, [searchString]);

  return (
    <Box>
      <Hidden mdDown>
        <HeaderDesktop searchString={searchString} disclaimerOpen={open} handleSearchChange={handleSearchChange}></HeaderDesktop>
      </Hidden>
      <Hidden mdUp>
        <HeaderMobile searchString={searchString} disclaimerOpen={open} handleSearchChange={handleSearchChange}></HeaderMobile>
      </Hidden>
    </Box>
  );
}

interface HeaderProps {
  searchString: string,
  disclaimerOpen: string,
  handleSearchChange: (event: any) => void
}

const HeaderDesktop: React.FC<HeaderProps> = ({
  searchString,
  disclaimerOpen,
  handleSearchChange
}: HeaderProps) => {
  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(resetSearch())
    dispatch(init())
  }

  return (
    <Box>
      <AppBar
        position="fixed"
        color="swagalWhite"
        sx={{
          borderBottom: "1px black solid",
          borderColor: "bgDefault.main"
        }}
        elevation={0}
      >
        <Disclaimer open={disclaimerOpen}></Disclaimer>
        <Toolbar sx={{
          borderBottom: "1px black solid",
          borderColor: "bgDefault.main",
          paddingBottom: "16px",
          paddingTop: "16px"
        }}>
          <Link color="inherit" to="/" onClick={handleNavigate}>
            <img src={Logo} height={32} alt="swagal" loading="lazy" />
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              height: "48px",
              alignItems: "center",
              border: "0.25px black solid",
              borderColor: "textSecondary.main",
              paddingRight: "4px",
              paddingLeft: "4px",
              borderRadius: "4px",
            }}
          >
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchString}
              onChange={handleSearchChange}
            />
            <Box justifyItems="flex-end">
              <Link to="/products">
                <Button
                  variant="contained"
                  color="swagalTeal"
                  onClick={() => dispatch(fetchProducts())}
                  startIcon={
                    <SearchIcon
                      sx={{ color: "swagalWhite.main", fontWeight: "600" }}
                    />
                  }
                  sx={{
                    color: "swagalWhite.main",
                    height: "40px",
                    fontWeight: "600",
                  }}
                  disableElevation
                >
                  SUCHEN
                </Button></Link>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          <Link to="/impressum" style={{ marginRight: "24px" }}><Button
            color="swagalBlack"
            variant="text">Impressum</Button></Link>
          <FeedbackDialogEntrypoint></FeedbackDialogEntrypoint>
        </Toolbar>
        <CategorySelection></CategorySelection>
      </AppBar>
    </Box>
  )
}

const HeaderMobile: React.FC<HeaderProps> = ({
  searchString,
  disclaimerOpen,
  handleSearchChange
}: HeaderProps) => {

  const dispatch = useDispatch();
  const handleNavigate = () => {
    dispatch(resetSearch())
    dispatch(init())
  }

  return (
    <Box>
      <AppBar
        position="fixed"
        color="swagalWhite"
        sx={{
          borderBottom: "1px black solid",
          borderColor: "bgDefault.main",
          paddingBottom: "8px",
        }}
        elevation={0}
      >
        <Disclaimer open={disclaimerOpen}></Disclaimer>
        <Toolbar
          sx={{ paddingTop: "8px" }}>
          <Link color="inherit" to="/" onClick={handleNavigate} style={{ marginRight: "12px" }}>
            <img src={logoSmall} height={32} alt="swagal" loading="lazy" />
          </Link>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              height: "48px",
              alignItems: "center",
              border: "0.25px black solid",
              borderColor: "textSecondary.main",
              paddingRight: "12px",
              paddingLeft: "12px",
              borderRadius: "8px",
              marginRight: "12px",
              width: "100%"
            }}
          >
            <StyledInputBaseMobile
              placeholder="Nach Produkten suchen..."
              inputProps={{ "aria-label": "search" }}
              value={searchString}
              onChange={handleSearchChange}
            />
          </Box>
          <FilterDialogEntrypoint></FilterDialogEntrypoint>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
