import { Box, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Box sx={{
      color: 'swagalTeal.main', paddingLeft: "16px", paddingTop: "8px", paddingBottom: "8px", display: "flex", position: "relative", justifyContent: "center",
      alignItems: "center"
    }} display="flex">
      <Typography fontSize={50} fontWeight="600">
        404 - Not Found
      </Typography>
    </Box>
  );
}