import { Box, Hidden } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { CartCategoryBreadcrumb } from "./CartCategoryBreadcrumb";
import CartHeader from "./CartHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  CartCategoryDto,
  CartItemComparisonDto,
} from "../../api";
import { CartProduct } from "./CartProduct";
import { buildCartSummaryForEnabledProductsInCategory } from "../../util/functions";
import { updateSummaryWith } from "../../features/cart/cartSummarySlice";
import { findCategoryByUrlId } from "../../domain/data";
import { changeCategoryTo } from "../../features/categoryselect/categoryselectSlice";

export default function CartCategoryProducts() {
  const dispatch = useDispatch();

  // fetch selected category from store
  const selectedCategory = useSelector(
    (state: any) => state.categoryselect.selected
  );

  // fetch all category data from store
  const shoppingCartCategories: CartCategoryDto[] = useSelector(
    (state: any) => state.cartData.data.categories
  );

  
  const { categoryId } = useParams();
  if (categoryId != findCategoryByUrlId(selectedCategory)?.urlId) {
    dispatch(changeCategoryTo(findCategoryByUrlId(categoryId as string)?.id))
  }

  // filter out current category data
  const currentCategory = shoppingCartCategories
    .filter(
      (cat: CartCategoryDto) =>
        cat.name === selectedCategory
    )
    .at(0);

  // update cart summary for current select products
  dispatch(updateSummaryWith(buildCartSummaryForEnabledProductsInCategory([])));

  return (
    <Box>
      <Hidden mdDown>
        <Box sx={{ paddingTop: "32px", marginTop: "70px", paddingBottom: "86px" }}>
          <CartCategoryBreadcrumb
            categoryId={selectedCategory as string}
          ></CartCategoryBreadcrumb>
          <CartHeader></CartHeader>
          {currentCategory?.cartItemComparisons.map(
            (priceComparison: CartItemComparisonDto, index: number) => (
              <CartProduct
                key={index}
                categoryId={selectedCategory}
                comparison={priceComparison}
                isLast={index === (currentCategory?.cartItemComparisons.length - 1)}
              ></CartProduct>
            )
          )}
          {/* ROUTER OUTLET: product details in drawer */}
          <Outlet></Outlet>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box sx={{ backgroundColor: "bgLight.main", paddingTop: "16px", paddingBottom: "104px" }}>
          <CartCategoryBreadcrumb
            categoryId={selectedCategory as string}
          ></CartCategoryBreadcrumb>
          {currentCategory?.cartItemComparisons.map(
            (priceComparison: CartItemComparisonDto, index) => (
              <CartProduct
                key={index}
                categoryId={selectedCategory}
                comparison={priceComparison}
                isLast={index === (currentCategory?.cartItemComparisons.length - 1)}
              ></CartProduct>
            )
          )}
          {/* ROUTER OUTLET: product details in drawer */}
          <Outlet></Outlet>
        </Box>
      </Hidden>
    </Box>
  );
}
