import { Box, Grid, IconButton, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterDialogEntrypoint from "./Filter";
import { useDispatch, useSelector } from "react-redux";
import { changeCategoryTo } from "../features/categoryselect/categoryselectSlice";
import { categories, findCategoryById } from "../domain/data";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { resetResults, updateSearchString } from "../features/search/searchSlice";
import Hidden from '@mui/material/Hidden';
import { fetchProducts } from "../async/actions";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    border: "1.5px solid white",
    borderRadius: "8px",
    color: "black",
    textTransform: "none",
    "&.Mui-selected, &.Mui-selected:hover": {
      border: "1.5px solid #54A492",
      backgroundColor: '#54A4921A',
      color: "#54A492",
      borderRadius: "8px"
    },
    '&:not(:first-of-type)': {
      borderRadius: "8px",
    },
    '&:first-of-type': {
      borderRadius: "8px",
    },
  },
}));

export default function CategorySelection() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedCategory = useSelector(
    (state: any) => state.categoryselect.selected
  );

  const { open } = useSelector((state: any) => state.disclaimer);
  const marginTop = open !== "false" ? "48px" : "0px"

  const handleCategoryChange = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: string,
  ) => {
    dispatch(changeCategoryTo(newCategory))
    if (location.pathname.includes("products")) {
      dispatch(resetResults())
      dispatch(fetchProducts())
    } else if (newCategory && !location.pathname.includes("products")) {
      navigate("/" + findCategoryById(newCategory)?.urlId);
    } else if (newCategory == null) {
      navigate("/")
    }
  };

  const handleCancelSearch = () => {
    dispatch(updateSearchString(""))
    if (selectedCategory != null) {
      navigate("/" + findCategoryById(selectedCategory)?.urlId);
    } else {
      navigate("/")
    }
  }

  if (location.pathname.includes("/impressum")) {
    return (<></>)
  }

  return (
    <Box>
      <Hidden mdDown>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Box
            alignItems="center"
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "8px",
              paddingBottom: "8px",
              paddingLeft: "12px",
              paddingRight: "12px",
              overflowX: "auto"
            }}
          >
            {location.pathname.includes("products") ? (

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: "1px solid black",
                  borderColor: "bgDefault.main",
                  paddingRight: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",

                  }}
                >
                  <SearchIcon></SearchIcon>
                  <Typography sx={{ whiteSpace: "nowrap" }}>Deine Suche</Typography>
                </Box>
                <IconButton
                  onClick={handleCancelSearch}
                  aria-label="delete"
                  sx={{
                    backgroundColor: "bgDefault.main",
                    padding: "4px",
                    marginLeft: "24px",
                  }}
                >
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Box>
            ) : (
              <></>
            )}

            <StyledToggleButtonGroup
              size="small"
              value={selectedCategory}
              exclusive
              onChange={handleCategoryChange}
              aria-label="text alignment"
            >
              {
                Object.values(categories).map((category) => (
                  <ToggleButton key={category.id as string} value={category.id} aria-label="left aligned" sx={{ display: "flex", flexDirection: "column" }}>
                    <span className={`material-symbols-outlined ${(category.id === selectedCategory) ? 'filled' : ''}`} style={{ fontSize: "32px" }}>{category.icon}</span>
                    <Typography marginTop="8px" fontSize={12} sx={{ whiteSpace: "nowrap" }}>{category.description}</Typography>
                  </ToggleButton>
                ))}
            </StyledToggleButtonGroup>

            <Grid item>

              <FilterDialogEntrypoint></FilterDialogEntrypoint>
            </Grid>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box

          alignItems="center"
          sx={{
            display: "flex",
            marginTop: marginTop,
            flexDirection: "row",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "12px",
            paddingRight: "12px",
            borderBottom: "1px black solid",
            borderColor: "bgDefault.main",
            overflowX: "scroll"
          }}
        >
          {location.pathname.includes("/products") ? (

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid black",
                borderColor: "bgDefault.main",
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SearchIcon sx={{ marginBottom: "8px", fontSize: "30px" }}></SearchIcon>
                <Typography sx={{ whiteSpace: "nowrap" }}>Deine Suche</Typography>
              </Box>
              <Link to="/">
                <IconButton
                  onClick={handleCancelSearch}
                  aria-label="delete"
                  sx={{
                    backgroundColor: "bgDefault.main",
                    padding: "4px",
                    marginLeft: "24px",
                  }}
                >
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Link>

            </Box>
          ) : (
            <></>
          )}

          <StyledToggleButtonGroup
            size="small"
            value={selectedCategory}
            exclusive
            onChange={handleCategoryChange}
            aria-label="text alignment"
          >
            {
              Object.values(categories).map((category) => (
                <ToggleButton key={category.id as string} value={category.id} aria-label="left aligned" sx={{ display: "flex", flexDirection: "column" }}>
                  <span className={`material-symbols-outlined ${(category.id === selectedCategory) ? 'filled' : ''}`} style={{ fontSize: "32px" }}>{category.icon}</span>
                  <Typography marginTop="8px" fontSize={12} sx={{ whiteSpace: "nowrap" }}>{category.description}</Typography>
                </ToggleButton>
              ))}
          </StyledToggleButtonGroup>
        </Box>
      </Hidden>
    </Box>
  );
}
