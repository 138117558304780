import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    openSnackBar: false,
  },
  reducers: {
    openNotification(state) {
      state.openSnackBar = true;
    },
    closeNotification(state) {
      state.openSnackBar = false;
    },
  },
});

export const { openNotification, closeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
