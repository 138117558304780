import { Box, Button, Hidden, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function About() {
  const { open } = useSelector((state: any) => state.disclaimer);
  const marginTop = open != "false" ? "60px" : "60px"
  const marginTopMobile = open != "false" ? "48px" : "0px"
  return (
    <Box>
      <Hidden lgDown>
        <Box sx={{ marginTop: marginTop, padding: "32px" }}>
          <Typography color="black" fontSize={20} lineHeight="24px" fontWeight="600" padding="16px">
            Rechtliches
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            Preisirrtümer stets vorbehalten. Markennamen und -bilder sind Eigentum der jeweiligen Inhaber.
          </Typography>

          <Typography color="black" fontSize={20} lineHeight="24px" fontWeight="600" padding="16px">
            Angaben gemäß § 25 Mediengesetz (Österreich)
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            Mario Luef MSc, Michael Neuhold MSc, Julian Jany MSc, Tobias Wecht MSc
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            The Social Hub CoWorking
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            Nordbahnstraße 47
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            1020 Wien, Österreich
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px" sx={{ color: "black", textDecoration: "none" }}>
            <a href="mailto:hello@swagal.at">hello@swagal.at</a>
          </Typography>
          <Box sx={{ marginTop: "48px" }}>
            <Link to="/" style={{ marginLeft: "16px" }}><Button
              color="swagalBlack"
              variant="text"><ArrowBackIcon sx={{ color: "swagalBlack.main", marginRight: "8px" }}></ArrowBackIcon>Zurück zur Übersicht</Button></Link>
          </Box>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box sx={{ marginTop: marginTopMobile, padding: "32px" }}>
          <Typography color="black" fontSize={20} lineHeight="24px" fontWeight="600" padding="16px">
            Rechtliches
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            Preisirrtümer stets vorbehalten. Markennamen und -bilder sind Eigentum der jeweiligen Inhaber.
          </Typography>

          <Typography color="black" fontSize={20} lineHeight="24px" fontWeight="600" padding="16px" paddingTop="32px">
            Angaben gemäß § 25 Mediengesetz (Österreich)
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            Mario Luef MSc, Michael Neuhold MSc, Julian Jany MSc, Tobias Wecht MSc
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            The Social Hub CoWorking
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            Nordbahnstraße 47
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px">
            1020 Wien, Österreich
          </Typography>
          <Typography color="black" fontSize={16} lineHeight="24px" paddingLeft="16px" sx={{ color: "black", textDecoration: "none" }}>
            <a href="mailto:hello@swagal.at">hello@swagal.at</a>
          </Typography>
          <Box sx={{ marginTop: "48px" }}>
            <Link to="/" style={{ marginLeft: "8px" }} ><Button
              color="swagalBlack"
              variant="text"><ArrowBackIcon sx={{ color: "swagalBlack.main", marginRight: "8px" }}></ArrowBackIcon>Zurück zur Übersicht</Button></Link>
          </Box>
        </Box>
      </Hidden>
    </Box>

  );
}
