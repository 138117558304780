import { Box, Button, Hidden, IconButton, Typography } from "@mui/material";
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import CloseIcon from "@mui/icons-material/Close";
import { closeDisclaimer } from "../features/disclaimer/disclaimerSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const Disclaimer: React.FC<{ open: string }> = ({
  open
}: { open: string }) => {
  const dispatch = useDispatch();
  const handleCloseDisclaimer = () => {
    dispatch(closeDisclaimer())
  }

  if (open == "false") {
    return <></>
  }

  return (
    <Box>
      <Hidden lgDown>
        <Box sx={{ backgroundColor: 'swagalTeal.main', paddingLeft: "16px", paddingTop: "8px", paddingBottom: "8px", display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", position: "relative" }} >
          <Typography color="white" fontSize={20} fontWeight="600">
            Preisirrtümer stets vorbehalten. Markennamen und -bilder sind Eigentum der jeweiligen Inhaber.
          </Typography>
          <Link to="/impressum">
            <Button variant="text" sx={{ marginLeft: "32px" }} endIcon={<EastOutlinedIcon sx={{ color: 'swagalWhite.main' }} />} color="swagalBlack" disableElevation>
              <Typography color="swagalWhite.main" fontSize={14} fontWeight={600}>MEHR ERFAHREN</Typography>
            </Button>
          </Link>
          <Box sx={{ position: "absolute", right: "16px" }}>
            <IconButton
              onClick={handleCloseDisclaimer}
              aria-label="delete"
              sx={{
                padding: "4px",
                marginLeft: "24px",
              }}
            >
              <CloseIcon sx={{ color: "white" }}></CloseIcon></IconButton>
          </Box>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box sx={{ backgroundColor: 'swagalTeal.main', paddingLeft: "16px", paddingTop: "8px", paddingBottom: "8px", display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", position: "relative" }} >
          <Typography color="white" fontSize={16} fontWeight="600">
            Preisirrtümer stets vorbehalten.
          </Typography>
          <Link to="/about">
            <IconButton
              aria-label="delete"
              sx={{
                padding: "4px",
                marginLeft: "8px",
              }}
            >
              <ArrowForwardIcon sx={{ color: "white" }}></ArrowForwardIcon></IconButton></Link>
          <Box sx={{ position: "absolute", right: "16px" }}>
            <IconButton
              onClick={handleCloseDisclaimer}
              aria-label="delete"
              sx={{
                padding: "4px",
                marginLeft: "24px",
              }}
            >
              <CloseIcon sx={{ color: "white" }}></CloseIcon></IconButton>
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
}