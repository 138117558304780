import { configureStore } from "@reduxjs/toolkit";
import feedbackReducer from "../features/feedback/feedbackSlice";
import filterReducer from "../features/filter/filterSlice";
import categoryselectReducer from "../features/categoryselect/categoryselectSlice";
import searchReducer from "../features/search/searchSlice";
import cartSummaryReducer from "../features/cart/cartSummarySlice";
import cartDataReducer from "../features/cart/cartDataSlice";
import disclaimerReducer from "../features/disclaimer/disclaimerSlice";
import introReducer from "../features/intro/introSlice";
import notificationReducer from "../features/notification/notificationSlice";
import thunk from "redux-thunk";

const rootReducer = {
  feedback: feedbackReducer,
  filter: filterReducer,
  categoryselect: categoryselectReducer,
  search: searchReducer,
  cartSummary: cartSummaryReducer,
  cartData: cartDataReducer,
  disclaimer: disclaimerReducer,
  intro: introReducer,
  notification: notificationReducer
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk]
});

export default store;
