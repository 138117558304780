import { Box } from "@mui/material";
import CartSummary from "./CartSummary";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchCart } from "../../async/actions";

export default function Cart() {
  const dispatch = useDispatch();
  dispatch(fetchCart())
  return (
    <Box>
      <Outlet></Outlet>
      <CartSummary></CartSummary>
    </Box>
  );
}