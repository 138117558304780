import { useTheme } from '@mui/material/styles';
import { Grid } from "@mui/material";

interface CartDividerProps {
    md: number
}

export const CartVerticalDivider: React.FC<CartDividerProps> = (props: CartDividerProps) => {
    const theme = useTheme();
    const bgDisabled = theme.palette.bgDisabled.main;

    return (
        <Grid md={props.md} item sx={{ borderRight: "1px solid", borderColor: bgDisabled, height: "24px" }} />
    );
};