import { createSlice } from '@reduxjs/toolkit';

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    stars: 5,
    message: 'Ich liebe swagal ❤️'
  },
  reducers: { 
    updateStars(state, param) {
      state.stars = param.payload
    },
    updateMessage(state, param) {
      state.message = param.payload
    },
    resetFeedback(state) {
      state.stars = 5
      state.message = 'Ich liebe swagal ❤️'
    }
  },
});

export const { updateStars, updateMessage, resetFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
