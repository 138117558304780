import { Box, Button, Divider, Drawer, Hidden, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Sheet from 'react-modal-sheet';
import { useEffect, useState } from "react";
import { findCategoryById, shops } from "../domain/data";
import { CartDtoShopsSelectedEnum, ProductControllerApi, ProductDetailDto, ProductDetailDtoCurrentPriceClassificationEnum, ProductDetailDtoShopEnum, ProductDtoUnitEnum } from "../api";
import { API_BASE_URL, API_CONFIG } from "../api-config";
import { useSelector } from "react-redux";
import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { LineChart } from "@mui/x-charts";
import moment from "moment";
import { useTheme, useMediaQuery } from "@mui/material";
import { getPrettyAmount } from "./search/ProductCard";
import { getUnitText } from "../util/functions";

interface ProductProps {
  product: ProductDetailDto
}

interface ProductIdProps {
  productId: String
}

export function ProductDetailsView() {

  return (
    <Box>
      <Hidden mdDown>
        <ProductDetailsDesktop></ProductDetailsDesktop>
      </Hidden>
    </Box>
  );
}

const ProductDetailsDesktop = () => {
  const [product, setProduct] = useState<ProductDetailDto | null>(null)
  const { productId } = useParams();
  const firstHyphenIndex: number = (productId as string).indexOf("-");

  const fetchProductDetails = async () => {
    if (firstHyphenIndex !== -1) {
      const shop: string = (productId as string).slice(0, firstHyphenIndex);
      const id: string = (productId as string).slice(firstHyphenIndex + 1);
      const api = new ProductControllerApi(API_CONFIG, API_BASE_URL);
      try {
        const response = await api.getProduct(shop as CartDtoShopsSelectedEnum, id);
        setProduct(response.data);
      } catch (error) {
        console.log(error)
      }
    } else {
      console.log("No hyphen found in the string.");
    }
  }

  useEffect(() => {
    fetchProductDetails()
  }, [productId])

  const { open } = useSelector((state: any) => state.disclaimer);
  const marginTop = open != "false" ? "238px" : "189px";

  if (product == null) {
    return (<></>)
  }

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: 400,
        height: "calc(100vh" + " - " + marginTop + ")",
        borderColor: "bgDefault.main",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 400, boxSizing: 'border-box',
          borderColor: "bgDefault.main",
          height: "calc(100vh" + " - " + marginTop + ")",
        },
        '& .MuiDrawer-root': {
          position: 'absolute',
          height: "calc(100vh" + " - " + marginTop + ")",
          top: "40px",
        },
        '& .MuiPaper-root': {
          marginTop: marginTop,
          height: "calc(100vh" + " - " + marginTop + ")",
        },
      }}
    >
      <Toolbar>
        <Typography fontSize={20} fontWeight={600} lineHeight="28px" variant="body1" sx={{
          textOverflow: "ellipsis", width: "300px", display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2
        }}>{product.name}</Typography>
        <Link to="/products">
          <IconButton
            aria-label="delete"
            sx={{
              backgroundColor: "swagalWhite.main",
              padding: "4px",
              marginLeft: "24px",
            }}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </Link>
      </Toolbar>
      <Divider sx={{ borderColor: "bgDefault.main" }} />
      <ProductDetailsContent product={product}></ProductDetailsContent>
    </Drawer>
  )
}


export const ProductDetailsMobileDirectly = () => {
  const [product, setProduct] = useState<ProductDetailDto | null>(null)
  const { productId } = useParams();
  const firstHyphenIndex: number = (productId as string).indexOf("-");
  const navigate = useNavigate();
  const fetchProductDetails = async () => {
    if (firstHyphenIndex !== -1) {
      const shop: string = (productId as string).slice(0, firstHyphenIndex);
      const id: string = (productId as string).slice(firstHyphenIndex + 1);
      const api = new ProductControllerApi(API_CONFIG, API_BASE_URL);
      try {
        const response = await api.getProduct(shop as CartDtoShopsSelectedEnum, id);
        setProduct(response.data);
      } catch (error) {
        console.log(error)
      }

      setOpen(true);
    } else {
      console.log("No hyphen found in the string.");
    }
  }

  useEffect(() => {
    fetchProductDetails()
  }, [productId])

  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    navigate("/products")
    setOpen(false);
  };

  if (product == null) {
    return (<>not found</>)
  }

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100% - ${40}px)`,
            overflow: 'visible',
            borderRadius: "24px"
          },
        }}
      />
      <Button sx={{ width: "140px", backgroundColor: "bgDefault.main", color: "swagalBlack.main", fontSize: "12px", fontWeight: "bold" }} onClick={handleOpen}>DETAILS ANZEIGEN</Button>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: 0,
            paddingTop: "24px",
            right: 0,
            left: 0,
            zIndex: 10
          }}
        >
          <Puller />
          <Box sx={{
            padding: '16px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid black",
            borderColor: "bgDefault.main"
          }}>
            <Typography whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis' fontWeight={600} fontSize={16}>{product.name}</Typography>
          </Box>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            marginTop: "32px",
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Box sx={{ marginTop: "40px" }}>
            <ProductDetailsContent product={product}></ProductDetailsContent>
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  )
}

const ProductDetailsContent: React.FC<ProductProps> = ({
  product
}: ProductProps) => {

  const getPriceClassificationDescription = () => {
    if (product.currentPriceClassification == ProductDetailDtoCurrentPriceClassificationEnum.Cheap) { return "günstig" }
    if (product.currentPriceClassification == ProductDetailDtoCurrentPriceClassificationEnum.Normal) { return "normal" }
    if (product.currentPriceClassification == ProductDetailDtoCurrentPriceClassificationEnum.Expensive) { return "teuer" }
  }

  const [frontPart, backPart] = product.currentPrice
    .toSwagalAmount({ includeCurrency: true, spaceBetween: false })
    .split(',');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classificationPointSize = isMobile ? '16px' : '8px';

  return (
    <Box sx={{ padding: "24px", overflowY: "auto" }}>

      {(!!product.brand && product.brand !== '')
        ? (<Typography fontSize={14} fontWeight={600} sx={{ display: "inline" }}>von <Typography component={'span'} fontSize={14} fontWeight={600} sx={{ textDecoration: 'underline', display: "inline" }}>{product.brand}</Typography></Typography>)
        : (<></>)}
      <Typography fontSize={14} fontWeight={600}>{getPrettyAmount(product)}</Typography>
      <Typography fontSize={14} fontWeight={400} lineHeight="32px">Kategorie: <Typography component={'span'} fontSize={14} fontWeight={400} sx={{ display: "inline" }}>{product.shopCategory + " > " + product.shopSubCategory}</Typography></Typography>

      <Box sx={{ height: "198px", display: "flex", marginTop: "16px", justifyContent: "center", alignItems: "center", backgroundColor: "bgLight.main", borderRadius: "8px, 8px, 0px, 0px", position: "relative" }}>
        <img loading="lazy" style={{ maxHeight: "163px", maxWidth: "163px" }} src={product.image} />

        <Box sx={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px", borderRadius: "100px", margin: "-15px", position: "absolute", right: "32px", top: "32px", backgroundColor: "bgDefault.main" }}>
          <Typography fontSize={12}>{getPrettyAmount(product)}</Typography>
        </Box>
        {product.salesBadge != null ? (
          <Box sx={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px", borderRadius: "100px", margin: "-15px", position: "absolute", right: "32px", top: "58px", backgroundColor: "swagalTeal.main" }}>
            <Typography fontSize={12} color="swagalWhite.main" fontWeight={700}>{product.salesBadge}</Typography>
          </Box>) : (<></>)
        }
      </Box>
      <a href={product.productUrl} target="_blank" style={{ textDecoration: "none" }}>
        <Box sx={{
          padding: "16px", display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", position: "relative", borderWidth: "0px 1px 1px 1px", "borderStyle": "solid", borderColor: "#FAFAFA",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px"
        }}>
          <Box sx={{
            width: "72px",
            height: "38px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FAFAFA"
          }}>
            <img
              src={shops[product.shop].logo as string}
              style={{ maxHeight: "25px", maxWidth: "46px" }}
              alt={product.shop}
              loading="lazy"
            />
          </Box>
          <Box sx={{ marginLeft: "16px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <Typography align="center" fontSize={32} fontWeight={600} color="swagalTeal.main">{frontPart}</Typography>
            <Typography sx={{ paddingBottom: "16px", paddingLeft: "4px" }} fontWeight={600} fontSize={16} color="swagalTeal.main">{backPart}</Typography>
          </Box>
          <Box sx={{ marginLeft: "16px", display: "flex", flexDirection: "column" }}>
            <Typography align="center" fontSize={12} fontWeight={700} color="textSecondary.main">€ {product.currentPrice?.toSwagalAmount({ includeCurrency: false }) + " / " + getPrettyAmount(product)}</Typography>
            <Typography fontWeight={400} fontSize={12} color="textSecondary.main">€ {product.normalizedPrice?.toSwagalAmount({ includeCurrency: false }) + " / " + getUnitText(product.unit)}</Typography>
          </Box>
          { product.productUrl != null ? (
          <Box sx={{ marginLeft: "16px", display: "flex", position: "absolute", right: "16px", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <span className="material-symbols-outlined" style={{ fontSize: "16px", color: "#8F8F8F" }}>arrow_outward</span>
          </Box>
          ) : (<></>)}
        </Box>
      </a>
      <Box sx={{ marginTop: "24px" }}>
        <Typography fontSize={20} fontWeight={600}>Preisentwicklung</Typography>
        <Box>
          <Typography fontSize={16} lineHeight="24px" fontWeight={500}>{`Die Preise für dieses Produkt sind aktuell ${getPriceClassificationDescription()}.`}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", paddingTop: "16px" }}>
            <Box sx={{ width: "80px", border: "3px solid black", borderColor: "swagalTeal.main", borderRadius: "6px", marginTop: "64px", position: "relative" }}>

              {product.currentPriceClassification == ProductDetailDtoCurrentPriceClassificationEnum.Cheap ? (<>
                <Box sx={{ right: -160, position: "absolute", backgroundColor: "bgDefault.main", top: "-50px", borderRadius: "8px", color: "swagalBlack.main", padding: "4px 12px 4px 12px" }}>
                  <Box sx={{ height: "10px", width: "10px", left: 22, position: "absolute", backgroundColor: "bgDefault.main", top: "27px", color: "swagalBlack.main", transform: "rotate(45deg)" }}></Box>
                  <Typography whiteSpace='nowrap' fontSize={16} fontWeight={400}><Typography component={'span'} fontSize={16} fontWeight={700}>{product.currentPrice.toSwagalAmount({ includeCurrency: true, spaceBetween: false })}</Typography> ist ein günstiger Preis</Typography>
                </Box>
                <Box sx={{ minWidth: classificationPointSize, minHeight: classificationPointSize, width: classificationPointSize, height: classificationPointSize, position: "absolute", backgroundColor: "swagalWhite.main", border: "4px solid black", top: "-8px", left: "32px", borderRadius: "50%" }}></Box>
              </>
              ) : (<></>)}
            </Box>

            <Box sx={{ width: "164px", border: "3px solid black", borderColor: "#FFD166", borderRadius: "6px", marginLeft: "4px", marginRight: "4px", marginTop: "64px", position: "relative" }}>
              {product.currentPriceClassification == ProductDetailDtoCurrentPriceClassificationEnum.Normal ? (<>
                <Box sx={{ left: -80, position: "absolute", backgroundColor: "bgDefault.main", top: "-50px", borderRadius: "8px", color: "swagalBlack.main", padding: "4px 12px 4px 12px" }}>
                  <Box sx={{ height: "10px", width: "10px", left: 155, position: "absolute", backgroundColor: "bgDefault.main", top: "27px", color: "swagalBlack.main", transform: "rotate(45deg)" }}></Box>
                  <Typography whiteSpace='nowrap' fontSize={16} fontWeight={400}><Typography component={'span'} fontSize={16} fontWeight={700}>{product.currentPrice.toSwagalAmount({ includeCurrency: true, spaceBetween: false })}</Typography> ist ein normaler Preis</Typography>
                </Box>
                <Box sx={{ minWidth: classificationPointSize, minHeight: classificationPointSize, width: classificationPointSize, height: classificationPointSize, position: "absolute", backgroundColor: "swagalWhite.main", border: "4px solid black", top: "-8px", left: "72px", borderRadius: "50%" }}></Box>
              </>) : (<></>)}
            </Box>
            <Box sx={{ width: "80px", border: "3px solid black", borderColor: "#C74B46", borderRadius: "6px", marginTop: "64px", position: "relative" }}>

              {product.currentPriceClassification == ProductDetailDtoCurrentPriceClassificationEnum.Expensive ? (<>
                <Box sx={{ left: -140, position: "absolute", backgroundColor: "bgDefault.main", top: "-50px", borderRadius: "8px", color: "swagalBlack.main", padding: "4px 12px 4px 12px" }}>
                  <Box sx={{ height: "10px", width: "10px", left: 175, position: "absolute", backgroundColor: "bgDefault.main", top: "27px", color: "swagalBlack.main", transform: "rotate(45deg)" }}></Box>
                  <Typography whiteSpace='nowrap' fontSize={16} fontWeight={400}><Typography component={'span'} fontSize={16} fontWeight={700}>{product.currentPrice.toSwagalAmount({ includeCurrency: true, spaceBetween: false })}</Typography> ist ein teurer Preis</Typography>
                </Box>
                <Box sx={{ minWidth: classificationPointSize, minHeight: classificationPointSize, width: classificationPointSize, height: classificationPointSize, position: "absolute", backgroundColor: "swagalWhite.main", border: "4px solid black", top: "-8px", left: "32px", borderRadius: "50%" }}></Box>
              </>
              ) : (<></>)}
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <LineChart
              margin={{
                left: 30,
                right: 30,
                top: 60,
                bottom: 20,
              }}
              sx={{
                padding: "0px", "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                  stroke: "#8F8F8F",
                  strokeWidth: 1
                },
                // leftAxis Line Styles
                "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                  stroke: "#8F8F8F",
                  strokeWidth: 1
                },
                '& .MuiAreaElement-root': {
                  strokeWidth: 3,
                  fill: "#54A49233",
                },
                '& .MuiChartsAxis-tick': {
                  borderColor: "#8F8F8F",
                  stroke: "#8F8F8F",
                  color: "#8F8F8F",
                  backgroundColor: "#8F8F8F",
                },
                '& .MuiChartsAxis-tickLabel': {
                  fill: "#8F8F8F",
                  fontSize: "10px",
                  fontWeight: "500"
                }
              }}
              xAxis={[{
                scaleType: 'point',
                data: product.dataPoints.timestamps.slice(-6).map(date =>
                  moment(date).format('D.M.YY').toString())
              }]}
              series={[
                {
                  id: "Price",
                  area: true,
                  curve: "stepBefore", showMark: true,
                  data: product.dataPoints.prices.slice(-6).map(price => Number.parseFloat(price.toSwagalAmount().replace(",", "."))),
                },
              ]}
              width={350}
              height={300}
            >
            </LineChart>
          </Box>
        </Box>
      </Box>
      {product.description != "" ? (
        <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "32px" }}>
          <Typography fontSize={20} fontWeight={600}>Produktbeschreibung</Typography>
          <div style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400" }} dangerouslySetInnerHTML={{ __html: product.description }} />
        </Box>
      ) : (<></>)}
      {(product.isAut || product.isBio || product.isVegan || product.isVegetarian) ? (
        <Box sx={{ display: "flex", flexDirection: "column", paddingTop: "32px" }}>
          <Typography fontSize={20} fontWeight={600}>Produkteigenschaften</Typography>
          <Box sx={{ display: "flex", marginTop: "8px" }}>
            {product.isAut ? (
              <Box sx={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px", borderRadius: "100px", backgroundColor: "swagalTeal.main", marginRight: "8px" }}>
                <Typography fontSize={12} color="swagalWhite.main" fontWeight={700}>Aus Österreich</Typography>
              </Box>) : (<></>)
            }
            {product.isBio ? (
              <Box sx={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px", borderRadius: "100px", backgroundColor: "swagalTeal.main", marginRight: "8px" }}>
                <Typography fontSize={12} color="swagalWhite.main" fontWeight={700}>Bio</Typography>
              </Box>) : (<></>)
            }
            {product.isVegan ? (
              <Box sx={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px", borderRadius: "100px", backgroundColor: "swagalTeal.main", marginRight: "8px" }}>
                <Typography fontSize={12} color="swagalWhite.main" fontWeight={700}>Vegan</Typography>
              </Box>) : (<></>)
            }
            {product.isVegetarian ? (
              <Box sx={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "8px", paddingRight: "8px", borderRadius: "100px", backgroundColor: "swagalTeal.main" }}>
                <Typography fontSize={12} color="swagalWhite.main" fontWeight={700}>Vegetarisch</Typography>
              </Box>) : (<></>)
            }
          </Box>
        </Box>
      ) : (<></>)}
    </Box>
  )
}

const Root = styled('div')(({ theme }) => ({
  height: '100%',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  position: 'absolute',
  top: 8,
  borderRadius: "6px",
  left: 'calc(50% - 15px)',
}));

export const SwipeableEdgeDrawer: React.FC<ProductIdProps> = ({
  productId
}: ProductIdProps) => {
  // const { window } = props;
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const [product, setProduct] = useState<ProductDetailDto | null>(null)
  const firstHyphenIndex: number = (productId as string).indexOf("-");
  const fetchProductDetails = async () => {
    if (firstHyphenIndex !== -1) {
      const shop: string = (productId as string).slice(0, firstHyphenIndex);
      const id: string = (productId as string).slice(firstHyphenIndex + 1);
      const api = new ProductControllerApi(API_CONFIG, API_BASE_URL);
      try {
        const response = await api.getProduct(shop as CartDtoShopsSelectedEnum, id);
        setProduct(response.data);
      } catch (error) {
        console.log(error)
      }
    } else {
      console.log("No hyphen found in the string.");
    }
  }

  useEffect(() => {
    fetchProductDetails()
  }, [productId])

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // This is used only for the example
  //const container = window !== undefined ? () => window().document.body : undefined;

  if (product == null) {
    return (<></>)
  }

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100% - ${40}px)`,
            overflow: 'visible',
            borderRadius: "24px"
          },
        }}
      />
      <Button sx={{ width: "140px", backgroundColor: "bgDefault.main", color: "swagalBlack.main", fontSize: "12px", fontWeight: "bold" }} onClick={handleOpen}>DETAILS ANZEIGEN</Button>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={0}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: 0,
            paddingTop: "24px",
            right: 0,
            left: 0,
            zIndex: 10
          }}
        >
          <Puller />
          <Box sx={{
            padding: '16px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid black",
            borderColor: "bgDefault.main"
          }}>
            <Typography textOverflow='ellipsis' overflow='hidden' whiteSpace='nowrap' fontWeight={600} fontSize={16}>{product.name}</Typography>
          </Box>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            marginTop: "32px",
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Box sx={{ marginTop: "40px" }}>
            <ProductDetailsContent product={product}></ProductDetailsContent>
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}