import { Category, Shop } from "./types";

import Hofer from "../media/shops/hofer.jpeg";
import Spar from "../media/shops/spar.png";
import Gurkerl from "../media/shops/gurkerl.webp";
import Billa from "../media/shops/billa.svg";

export const findCategoryByUrlId = (urlId: String) => {
  return categories.find((category) => category.urlId === urlId);
};

export const findCategoryById = (id: String) => {
  return categories.find((category) => category.id === id);
};

export const categories: Category[] = [
  {
    id: "BROT_BACKWAREN",
    urlId: "brot-backwaren",
    description: "Brot & Backwaren",
    icon: "bakery_dining",
  },
  {
    id: "OBST_GEMUESE",
    urlId: "obst-gemuese",
    description: "Obst & Gemüse",
    icon: "nutrition",
  },
  {
    id: "KUEHLWAREN",
    urlId: "kuehlwaren",
    description: "Kühlwaren",
    icon: "kitchen",
  },
  {
    id: "GETRAENKE",
    urlId: "getraenke",
    description: "Getränke",
    icon: "liquor",
  },
  {
    id: "SUESSES_SALZIGES",
    urlId: "suesses-salziges",
    description: "Süßes & Salziges",
    icon: "icecream",
  },
  {
    id: "TIEFKUEHLWAREN",
    urlId: "tiefkuehlwaren",
    description: "Tiefkühlwaren",
    icon: "ac_unit",
  },
  {
    id: "GRUNDNAHRUNGSMITTEL",
    urlId: "grundnahrungsmittel",
    description: "Grundnahrungsmittel",
    icon: "restaurant",
  },
  {
    id: "HAUSHALT_DROGERIE",
    urlId: "haushalt",
    description: "Haushalt & Drogerie",
    icon: "storefront",
  },
  {
    id: "TIERBEDARF",
    urlId: "tierbedarf",
    description: "Tierbedarf",
    icon: "pet_supplies",
  },
];

export const shops: Record<string, Shop> = {
  INTERSPAR: {
    id: "INTERSPAR",
    description: "Interspar",
    logo: Spar,
  },
  HOFER: {
    id: "HOFER",
    description: "Hofer",
    logo: Hofer,
  },
  BILLA: {
    id: "BILLA",
    description: "Billa",
    logo: Billa,
  }/*,
  GURKERL: {
    id: "GURKERL",
    description: "Gurkerl",
    logo: Gurkerl,
  },*/
};
