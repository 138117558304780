import { Box, Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { shops } from "../../domain/data";
import { useParams } from "react-router-dom";
import { FilterState } from "../../features/filter/filterSlice";
import { CartDivider } from "./CartDivider";

export default function CartHeader() {
  const { categoryId } = useParams();
  const isProductComparison = !!categoryId;
  const selectedShops = useSelector((state: FilterState) => state.filter.data.selectedShops);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columnSpacing={2}
        padding={2}
      >
        <Grid md={3} item>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography marginTop="8px" fontSize={14} fontWeight={600}>
              {isProductComparison ? "Artikel" : "Kategorie"}
            </Typography>
          </Box>
        </Grid>
        {selectedShops.map((shop, index) => (
          <Grid md={1} item key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={shops[shop].logo as string}
                style={{maxHeight: "40px", maxWidth: "60px"}}
                alt="swagal"
                loading="lazy"
              />
            </Box>
          </Grid>
        ))}
        <Grid md={1} item></Grid>
        <Grid md={1} item>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Typography
              marginTop="8px"
              fontSize={14}
              fontWeight={400}
              align="center"
            >
              Diff. € / %
            </Typography>
          </Box>
        </Grid>
        {isProductComparison || <Grid md={1} item>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Typography
              marginTop="8px"
              fontSize={14}
              fontWeight={400}
              align="center"
            >
              Ein- / Ausblenden
            </Typography>
          </Box>
        </Grid>}
      </Grid>

      <CartDivider md={(isProductComparison ? 5 : 6) + selectedShops.length} />
    </>
  );
}
