import { createSlice } from '@reduxjs/toolkit';

const categoryselectSlice = createSlice({
  name: 'categoryselect',
  initialState: {
    selected: null
  },
  reducers: {
    changeCategoryTo: (state, param) => {
      //console.log("payload: " + param.payload)
      state.selected = param.payload
    },
    init: (state) => {
      state.selected = null
    }
  },
});

export const { changeCategoryTo, init  } = categoryselectSlice.actions;

export default categoryselectSlice.reducer;