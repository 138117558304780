import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Typography,
useTheme,
  fabClasses,
} from "@mui/material";
import { CartItemComparisonDto, ProductDto } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { cartProductChange } from "../../features/cart/cartDataSlice";
import { shops } from "../../domain/data";
import { calcCartProductStats, UIProductPrice } from "./CartProductStats";
import { FilterState } from "../../features/filter/filterSlice";
import { CartVerticalDivider } from "./CartVerticalDivider";
import { PriceChip } from "./PriceChip";
import { CartDivider } from "./CartDivider";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { getUnitText } from "../../util/functions";

interface CartProductProps {
  comparison: CartItemComparisonDto;
  categoryId: String;
  isLast: boolean;
}

const getShortenedName = (product: ProductDto): string => {
  const brand = product.brand
    ?.trim()
    ?.toLowerCase();

  if (!brand) {
    return product.name;
  }

  if (product.name.trim().toLowerCase().startsWith(brand)) {
    const shortName = product.name
      .trim()
      .substring(brand.length);

    if (shortName.trim() === '') {
      return product.name;
    }
    return shortName;
  }

  return product.name;
};


export const CartProduct: React.FC<CartProductProps> = (
  props: CartProductProps
) => {
  const [isDetailView, setIsDetailView] = useState(false)
  const [expanded, setExpanded] = useState<false | number>(0);
  const dispatch = useDispatch();
  const selectedShops = useSelector((state: FilterState) => state.filter).data.selectedShops;

  const cartProductStats = calcCartProductStats(selectedShops, props.comparison);

  const handleToggleDetailView = () => {
    setIsDetailView(!isDetailView)
  }

  const theme = useTheme();
  const categoryTextColor = theme.palette.swagalBlack.main;

  const handleOnMouseOver = () => {
    setIsDetailView(true)
  }

  const handleOnMouseLeave = () => {
    setIsDetailView(false)
  }

  const buildRefAmountAndUnit = (): string => {
    if (cartProductStats.referenceAmount == null) return ""
    return ` ${cartProductStats.referenceAmount?.toWeight()} ${getUnitText(cartProductStats.unit)}`
  }
  
  return (
    <Box>
      <Hidden mdDown>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            columnSpacing={2}
            padding={2}
          >
            <Grid md={3} item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  position: "relative"
                }}
              >
                <FormControlLabel
                  onClick={() => dispatch(cartProductChange({ catId: props.categoryId, compId: props.comparison.id }))}
                  control={<Checkbox color="swagalTeal" checked={props.comparison.enabled} />}
                  label={<Typography fontSize="16px" lineHeight="24px" fontWeight={600}>{props.comparison.id + buildRefAmountAndUnit()}</Typography>}
                />
                <IconButton
                      aria-label="delete"
                      color={"swagalBlack"}
                      sx={{ padding: "8px", position: "absolute", right: "0px" }}
                      onClick={handleToggleDetailView}
                    >
                      <span style={{ fontSize: "28px" }} className="material-symbols-outlined">shopping_cart</span>
                    </IconButton>
              </Box>
            </Grid>
            {cartProductStats.productShopPrices.map((cartProduct: UIProductPrice, index: number) => (
              <Grid key={index} md={1} item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PriceChip
                    price={cartProduct.price}
                    highlight={cartProduct.leastExpensive}
                    disabled={false}
                  />
                </Box>
              </Grid>)
            )}
            <CartVerticalDivider md={1} />
            <Grid md={1} item>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
{Math.round(cartProductStats.absDiff) === 0
              ? <span style={{ fontSize: "18px", lineHeight: "24px", color: categoryTextColor }} className="material-symbols-outlined">equal</span>
              : <>
                <Typography
                  fontSize={16}
                  lineHeight="24px"
                  color={categoryTextColor}
                >
                  {cartProductStats.absDiff.toSwagalAmount()}
                </Typography>
                <Typography
                  fontSize={14}
                  lineHeight="24px"
                  color={categoryTextColor}
                >
{/* absDiff > 0 */}
                  {cartProductStats.relDiff}%
                </Typography>
</>
            }
              </Box>
            </Grid>
          </Grid>
          {[0].map((i) => (
            <Accordion expanded={isDetailView} setExpanded={handleToggleDetailView} categoryId={props.categoryId} comparison={props.comparison} isLast={props.isLast} />))}
          {props.isLast || <CartDivider md={5 + selectedShops.length} />}
        
      </Hidden>

      <Hidden mdUp>
        <Box
          justifyContent="flex-start"
          alignItems="center"
          borderRadius="16px"
          sx={{
            boxShadow: "0px -1px 10px 0px #0000001A",
            backgroundColor: "swagalWhite.main",
            margin: "16px",
          }}
        >
          <Box sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingTop: props.comparison.enabled ? "24px" : "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid",
            borderBottomColor: "bgDefault.main"
          }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                position: "relative"
              }}
            >

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  width: "100%"
                }}
              >
                <FormControlLabel
                  sx={{ lineHeight: "24px", height: "24px", width: "90%" }}
                  onClick={() => dispatch(cartProductChange({ catId: props.categoryId, compId: props.comparison.id }))}
                  control={<Checkbox color="swagalTeal" checked={props.comparison.enabled} />}
                  label={<Typography fontSize="16px" lineHeight="24px" fontWeight={600}>{props.comparison.id + buildRefAmountAndUnit()}</Typography>}
                />
                <Box sx={{ position: "absolute", right: "0px" }}>
                  {isDetailView ? (
                    <IconButton
                      aria-label="delete"
                      color={"swagalBlack"}
                      sx={{ padding: 0 }}
                      onClick={handleToggleDetailView}
                    >
                      <span style={{ fontSize: "28px" }} className="material-symbols-outlined">overview_key</span>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      color={"swagalBlack"}
                      sx={{ padding: 0 }}
                      onClick={handleToggleDetailView}
                    >
                      <span style={{ fontSize: "28px" }} className="material-symbols-outlined">shopping_cart</span>
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          {props.comparison.enabled && <Box sx={{ borderBottom: "1px solid", borderColor: "bgDefault.main" }}>
            <Grid container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {isDetailView
                ? (<MobileCartProductDetail categoryId={props.categoryId} comparison={props.comparison} isLast={props.isLast}></MobileCartProductDetail>)
                : (<>
                  {cartProductStats.productShopPrices.map((cartProduct: UIProductPrice, index: number) => (
                    <Grid xs={5.7} item key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "12px",
                          marginBottom: "12px"
                        }}
                      >
                        {cartProduct.leastExpensive ? (
                          <>
                            <Box sx={{ backgroundColor: "teal20.main", borderRadius: "4px 0px 0px 4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img
                                src={shops[cartProduct.shopId].logo as string}
                                style={{
                                  maxHeight: "40px",
                                  maxWidth: "40px",
                                  margin: "auto",
                                  opacity: 1.0
                                }}
                                alt="swagal"
                                loading="lazy"
                              />
                            </Box>
                            <Box sx={{ backgroundColor: "teal10.main", borderRadius: "0px 4px 4px 0px", padding: "4px", width: "90px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              {/* width needs to be larger than in Figma to accomodate prices above 99€ (88px needed) ------------^ */}
                              <Typography
                                fontSize={20}
                                fontWeight={500}
                                align="center"
                                sx={{ color: "swagalBlack.main" }}
                              >
                                {cartProduct.price.toSwagalAmount()}
                              </Typography>
                            </Box>
                          </>) : (
                          <>
                            <Box sx={{ backgroundColor: "bgLight.main", borderRadius: "4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img
                                src={shops[cartProduct.shopId].logo as string}
                                style={{
                                  maxHeight: "40px",
                                  maxWidth: "40px",
                                  margin: "auto",
                                  opacity: 1.0
                                }}
                                alt="swagal"
                                loading="lazy"
                              />
                            </Box>
                            <Box sx={{ backgroundColor: "swagalWhite.main", borderRadius: "4px", padding: "4px", width: "90px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <Typography
                                fontSize={20}
                                fontWeight={400}
                                align="center"
                                sx={{ color: "swagalBlack.main" }}
                              >
                                {cartProduct.price.toSwagalAmount()}
                              </Typography>
                            </Box>
                          </>)
                        }
                      </Box>
                    </Grid>)
                  )}</>)}
            </Grid>
          </Box>
          }

{(props.comparison.enabled && Math.round(cartProductStats.absDiff) !== 0) &&
          <Box
            sx={{
              paddingLeft: "24px",
              paddingRight: "24px",
              paddingTop: "12px",
              paddingBottom: "12px",
                            display: "flex",
              flexDirextion: "row",
              flexFlow: "space-between"
            }}>
            <Typography
              fontSize="14px"
              fontWeight={500}
              lineHeight={"24px"}
              sx={{ color: "swagalBlack.main", flexGrow: 1 }}
            >
              Diff € / %
            </Typography>
            <Typography
              fontSize="14px"
              fontWeight={400}
              lineHeight={"24px"}
              align="right"
              sx={{ color: "swagalBlack.main", flexGrow: 1 }}
            >
              {cartProductStats.absDiff.toSwagalAmount()} / {cartProductStats.relDiff}%
            </Typography>
          </Box>
}

        </Box>
      </Hidden>
    </Box>
  );
};

const MobileCartProductDetail: React.FC<CartProductProps> = (
  props: CartProductProps
) => {
  const selectedShops = useSelector((state: FilterState) => state.filter).data.selectedShops;
  const cartProductStats = calcCartProductStats(selectedShops, props.comparison);
  return (
    <Box sx={{ paddingLeft: "24px", paddingRight: "24px", paddingTop: "0px", paddingBottom: "0px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
      {props.comparison.products.map((product, index) => (

        <a key={product.id} href={product.productUrl} target="_blank" style={{ width: "100%", textDecoration: "none" }}>
          <Box sx={{
            width: "100%", position: "relative", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", paddingTop: "8px", paddingBottom: "8px",
            borderBottom: `${index + 1 == props.comparison.products.length ? 0 : 1}px solid`, borderColor: "bgDefault.main"
          }}>

            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
              <Box sx={{ backgroundColor: "bgLight.main", borderRadius: "4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "8px" }}>
                <img
                  src={shops[product.shop].logo as string}
                  style={{
                    maxHeight: "40px",
                    maxWidth: "40px",
                    margin: "auto",
                    opacity: 1.0
                  }}
                  alt="swagal"
                  loading="lazy"
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }} >
                <Typography color="swagalBlack.main" fontSize="14px" fontWeight="600" sx={{
                  transition: 'max-width 0.2s',
                  width: "200px",
                  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                }}>
                  {getShortenedName(product)}
                </Typography>
                <Typography color="swagalBlack.main" fontSize="12px" >
                  {(!!product.brand && product.brand !== '')
                    ? product.brand
                    : '-'}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ backgroundColor: `${cartProductStats.productShopPrices.filter(productShopPrice => productShopPrice.shopId == product.shop)[0]?.leastExpensive ? "teal20.main" : "bgDefault.main"}`, borderRadius: "8px", padding: "2px 4px 2px 4px", display: "flex", flexDirection: "row", justifyContent: "felx-start", alignItems: "flex-start", position: "absolute", right: "0px", bottom: "8px" }}>
              <Typography color="swagalBlack.main" fontSize="10px" fontWeight={cartProductStats.productShopPrices.filter(productShopPrice => productShopPrice.shopId == product.shop)[0]?.leastExpensive ? 600 : 400} >{cartProductStats.productShopPrices.filter(productShopPrice => productShopPrice.shopId == product.shop)[0]?.price.toSwagalAmount()}</Typography>
            </Box>

            <Box sx={{ marginLeft: "16px", display: "flex", position: "absolute", right: "0px", top: "8px", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <span className="material-symbols-outlined" style={{ fontSize: "16px", color: "#8F8F8F" }}>arrow_outward</span>
            </Box>

          </Box>
        </a>
      ))}
    </Box>
  )
}

const DesktopCartProductDetail: React.FC<CartProductProps> = (
  props: CartProductProps
) => {
  const selectedShops = useSelector((state: FilterState) => state.filter).data.selectedShops;
  const cartProductStats = calcCartProductStats(selectedShops, props.comparison);
  return (
    <Box sx={{ paddingTop: "0px", paddingBottom: "0px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
      {props.comparison.products.map((product, index) => (

        <a key={product.id} href={product.productUrl} target="_blank" style={{ width: "100%", textDecoration: "none", cursor: "pointer"}}>
          <Box sx={{
            width: "100%", position: "relative", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", paddingTop: "8px", paddingBottom: "8px", borderRadius: "8px",
            borderBottom: `${index + 1 == props.comparison.products.length ? 0 : 1}px solid`, paddingLeft: "8px", paddingRight: "8px", borderColor: "bgDefault.main", '&:hover': { backgroundColor: "bgDefault.main"} 
          }}>

            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
              <Box sx={{ backgroundColor: "bgLight.main", borderRadius: "4px", padding: "4px", width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "8px" }}>
                <img
                  src={shops[product.shop].logo as string}
                  style={{
                    maxHeight: "40px",
                    maxWidth: "40px",
                    margin: "auto",
                    opacity: 1.0
                  }}
                  alt="swagal"
                  loading="lazy"
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }} >
                <Typography color="swagalBlack.main" fontSize="16px" fontWeight="600" >
                  {getShortenedName(product)}
                </Typography>
                <Typography color="swagalBlack.main" fontSize="14px" >
                  {(!!product.brand && product.brand !== '')
                      ? product.brand
                      : '-'}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ backgroundColor: `${cartProductStats.productShopPrices.filter(productShopPrice => productShopPrice.shopId == product.shop)[0]?.leastExpensive ? "teal20.main" : "bgDefault.main"}`, borderRadius: "8px", padding: "2px 4px 2px 4px", display: "flex", flexDirection: "row", justifyContent: "felx-start", alignItems: "flex-start", position: "absolute", right: "12px", bottom: "8px" }}>
              <Typography color="swagalBlack.main" fontSize="14px" fontWeight={cartProductStats.productShopPrices.filter(productShopPrice => productShopPrice.shopId == product.shop)[0]?.leastExpensive ? 600 : 400} >{cartProductStats.productShopPrices.filter(productShopPrice => productShopPrice.shopId == product.shop)[0]?.price.toSwagalAmount()}</Typography>
            </Box>

            <Box sx={{ marginLeft: "16px", display: "flex", position: "absolute", right: "12px", top: "8px", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <span className="material-symbols-outlined" style={{ fontSize: "16px", color: "#8F8F8F" }}>arrow_outward</span>
            </Box>

          </Box>
        </a>
      ))}
    </Box>
  )
}

// @ts-ignore
const Accordion = ({ expanded, setExpanded, categoryId, comparison, isLast }) => {
  const isOpen = expanded;

  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columnSpacing={2}
      >
        <Grid md={8} item>
          <AnimatePresence initial={false}>
            {isOpen && (
              <motion.section
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 }
                }}
                transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <motion.div
                  variants={{ collapsed: { scale: 1 }, open: { scale: 1 } }}
                  transition={{ duration: 0.4 }}
                  className="content-placeholder"
                >
                  <DesktopCartProductDetail categoryId={categoryId} comparison={comparison} isLast={isLast}></DesktopCartProductDetail>
                </motion.div>
              </motion.section>
            )}
          </AnimatePresence>
        </Grid>
      </Grid>
    </>
  );
};
