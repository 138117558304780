import { createSlice } from '@reduxjs/toolkit';

const disclaimerSlice = createSlice({
  name: 'disclaimer',
  initialState: {
    open: localStorage.getItem('disclaimerOpen')
  },
  reducers: { 
    closeDisclaimer(state) {
      state.open = "false"
      localStorage.setItem('disclaimerOpen', "false")
    }
  },
});

export const { closeDisclaimer } = disclaimerSlice.actions;

export default disclaimerSlice.reducer;