import { createSlice, current } from "@reduxjs/toolkit";
import { fetchCart } from "../../async/actions";
import { CartCategoryDto, CartItemComparisonDto } from "../../api";

const cartDataSlice = createSlice({
  name: "cartData",
  initialState: {
    data: {
      categories: [],
    },
  },
  reducers: {
    cartDataChange: (state, param) => {
      //state.selected = param.payload
      const test = state.data.categories.find(
        (cat: CartCategoryDto) => cat.name === param.payload
      );
      if (test) {
        (test as CartCategoryDto).enabled = !(test as CartCategoryDto).enabled;
      }
      state.data.categories.sort((a: CartCategoryDto, b: CartCategoryDto) => {
        const firstCharA = a.name.charAt(0).toLowerCase();
        const firstCharB = b.name.charAt(0).toLowerCase();
        if (firstCharA < firstCharB) {
          return -1;
        } else if (firstCharA > firstCharB) {
          return 1;
        } else {
          return 0;
        }
      });
      //console.log(current(state));
    },
    cartProductChange: (state, param) => {
      //state.selected = param.payload
      const category = state.data.categories.find(
        (cat: CartCategoryDto) => cat.name === param.payload.catId
      );
      if (category) {
        const priceComparison = (
          category as CartCategoryDto
        ).cartItemComparisons.find(
          (priceComp: CartItemComparisonDto) =>
            priceComp.id === param.payload.compId
        );
        if (priceComparison) {
          (priceComparison as CartItemComparisonDto).enabled = !(
            priceComparison as CartItemComparisonDto
          ).enabled;
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCart.pending, (state, action) => {
        // nothing to do
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        // nothing to do
      });
  },
});

export const { cartDataChange, cartProductChange } = cartDataSlice.actions;

export default cartDataSlice.reducer;
